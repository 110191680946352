import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

export const TextAnimation = ({ children, className }) => {
  gsap.registerPlugin(ScrollTrigger);
  const trigger = useRef();
  useEffect(() => {
    const tl = gsap.timeline();
    tl.from(trigger.current.children, {
      y: 150,
      x: 150,
      scale: 1.2,
      autoAlpha: 0,
      delay: 1.3,
    });
  }, []);

  return (
    <div className={className} ref={trigger}>
      {children}
    </div>
  );
};
