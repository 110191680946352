import React from 'react';
import ReactMarkdown from 'react-markdown';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Facebook from 'assets/svg/facebook.svg';
import Instagram from 'assets/svg/instagram.svg';
import Mail from 'assets/svg/mail-logo.svg';
import LinkedIn from 'assets/svg/linkedin.svg';
import Close from 'assets/svg/ModalClose.svg';

export const PeopleBio = ({ data, closeModal }: any) => {
  const {
    name,
    tagline,
    description,
    image,
    socialMediaLinks,
    connectWithMeText,
  } = data;
  const Image: any = getImage(image.localFile);
  return (
    <div className="people-bio">
      <div className="people-bio-heading">
        <div className="button-wrapper">
          <button type="button" onClick={closeModal}>
            <Close />
          </button>
        </div>
      </div>
      <div className="people-bio-background">
        <div className="people-bio-wrapper">
          <div className="people-bio-image">
            {Image && <GatsbyImage image={Image} alt={name} />}
          </div>
          <div className="people-bio-content">
            {tagline && <h2 className="people-bio-desc font-lg">{tagline}</h2>}
            <div className="people-bio-summary font-body">
              {description?.data?.description && (
                <ReactMarkdown className="htmlcontent">
                  {description.data.description}
                </ReactMarkdown>
              )}
            </div>
          </div>
        </div>
        <div className="people-bio-connect container">
          <div className="people-bio-connect-wrapper">
            <h3 className="people-bio-connect-title">
              {connectWithMeText && <span>{connectWithMeText}</span>}
            </h3>

            <div className="people-bio-connect-link">
              {socialMediaLinks && socialMediaLinks.mailLink ? (
                <a
                  target="_blank"
                  href={`mailto:${socialMediaLinks.mailLink}`}
                  rel="noreferrer"
                >
                  <Mail />
                </a>
              ) : null}
              {socialMediaLinks && socialMediaLinks.facebookLink ? (
                <a
                  target="_blank"
                  href={socialMediaLinks.facebookLink}
                  rel="noreferrer"
                >
                  <Facebook />
                </a>
              ) : null}
              {socialMediaLinks && socialMediaLinks.linkedinLink ? (
                <a
                  target="_blank"
                  href={socialMediaLinks.linkedinLink}
                  rel="noreferrer"
                >
                  <LinkedIn />
                </a>
              ) : null}
              {socialMediaLinks && socialMediaLinks.instagramLink ? (
                <a
                  target="_blank"
                  href={socialMediaLinks.instagramLink}
                  rel="noreferrer"
                >
                  <Instagram />
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
