/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import Helmet from 'react-helmet';
import { withPrefix } from 'gatsby';
import Logo from 'assets/images/footer-logo-tm.png';
import Instagram from 'assets/svg/instagram.svg';
import Facebook from 'assets/svg/facebook.svg';
import LinkedIn from 'assets/svg/linkedin.svg';
import Arrow from 'assets/svg/readarrow.svg';
import { CTALink, ScrollTop } from 'components';

export const Footer = ({ footer, socialMediaLinks }: any) => {
  const { footerNav, location } = footer;
  return (
    <footer className="footer">
      <Helmet>
        <script
          src={withPrefix('klavio-subscribe.js')}
          type="text/javascript"
        />
      </Helmet>
      <div className="container">
        <div className="footer-section">
          <div className="footer-section-column">
            <div className="footer-section-contact">
              <form
                id="klaviyo_subscribe"
                action="//manage.kmail-lists.com/subscriptions/subscribe"
                data-ajax-submit="//manage.kmail-lists.com/ajax/subscriptions/subscribe"
                method="GET"
                target="_blank"
              >
                <input type="hidden" name="g" value="Sm7nuq" />
                <input type="hidden" name="$source" value="Source" />
                <input
                  type="hidden"
                  name="$fields"
                  value="$first_name, $last_name, organization"
                />
                <div className="klaviyo_field_group klaviyo_form_actions">
                  <h3 className="subscribe-title">Subscribe</h3>
                  <div className="subscribe-tagline">
                    Join the Tastyone family and stay updated
                  </div>
                  <div className="subscribe-form">
                    <div className="subscribe-form-input">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        required
                      />
                    </div>
                    <div className="subscribe-form-inner">
                      <div className="subscribe-form-input subscribe-form-name">
                        <input
                          type="text"
                          name="$first_name"
                          placeholder="First Name"
                          required
                          id="k_id_first_name"
                        />
                      </div>
                      <div className="subscribe-form-input subscribe-form-name">
                        <input
                          type="text"
                          name="$last_name"
                          placeholder="Last Name"
                          required
                          id="k_id_last_name"
                        />
                      </div>
                    </div>
                    <div className="subscribe-form-inner-klaviyo">
                      <div className="subscribe-form-business">
                        <input
                          type="text"
                          name="organization"
                          placeholder="Business Name"
                          required
                          id="k_id_organization"
                        />
                      </div>
                      <div className="klaviyo_form_actions_button">
                        <button type="submit">
                          <Arrow />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="success_message" style={{ display: 'none' }} />
              </form>
            </div>
            <div className="logo">
              <CTALink ctaLink="/" className="footer-logo">
                <img src={Logo} alt="TastyOne" />
              </CTALink>
            </div>
          </div>

          <div className="footer-section-column two">
            <div className="footer-page-links">
              <div className="footer-page-links-section one">
                <ul className="footer-links">
                  {footerNav.map((nav: any) => (
                    <li key={nav.id}>
                      <CTALink ctaLink={nav.slug}>{nav.title}</CTALink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="footer-section-address">
              <div className="location">
                <h4>Location:</h4>
                <p>{location.data.location}</p>
              </div>

              <div className="icons">
                <h4>Follow Us</h4>
                <ul className="social-icons">
                  <li>
                    <a
                      target="_blank"
                      href={socialMediaLinks?.instagramLink}
                      rel="noreferrer"
                    >
                      <Instagram />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={socialMediaLinks?.facebookLink}
                      rel="noreferrer"
                    >
                      <Facebook />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={socialMediaLinks?.linkedinLink}
                      rel="noreferrer"
                    >
                      <LinkedIn />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-credits">
          Designed by{' '}
          <a href="https://lovemark.com.au/" target="_blank">
            Lovemark
          </a>{' '}
          and built by{' '}
          <a href="https://ozzi.tech/" target="_blank">
            Ozzitech
          </a>
        </div>
      </div>
      <ScrollTop />
    </footer>
  );
};
