import React, { FC, useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
interface ButtonHoverAnimationProps {
  children: any;
  className?: string;
  scaleFrom?: any;
  scaleTo?: any;
}

export const ButtonHoverAnimation: FC<ButtonHoverAnimationProps> = ({
  children,
  className,
  scaleFrom,
  scaleTo,
}) => {
  const [Visible, inVisible] = useState(false);
  const visibleClass = Visible ? 'Visible' : '';
  const buttonRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  useEffect(() => {
    const element: any = buttonRef.current;

    const Timeline = gsap.fromTo(
      element,
      1,
      { y: 0, transform: `scale(${scaleFrom})` },
      {
        y: 0,
        transform: `scale(${scaleTo})`,
        webkittransform: `scale(${scaleTo})`,
        ease: 'power1.inOut',
        scrollTrigger: {
          trigger: element,
          scrub: true,
          start: 'top bottom',
          end: 'bottom top',
        },
      },
      1
    );

    const cardSlider = document.querySelector(
      '.cardslider-wrapper .button-hover'
    );

    buttonRef.current.addEventListener('mouseenter', () => {
      Timeline.play();
      if (!cardSlider) {
        inVisible(true);
      }
    });
    buttonRef.current.addEventListener('mouseleave', () => {
      if (!cardSlider) {
        inVisible(false);
      }
      Timeline.reverse();
    });
  }, [buttonRef.current]);
  return (
    <div
      ref={buttonRef}
      className={`button-hover 
        ${className ?? className} ${visibleClass}`}
    >
      {children}
    </div>
  );
};
