/* eslint-disable import/no-unresolved */
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ReadmoreArrow from 'assets/svg/readmore.svg';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { CTALink } from 'components';

export const VerticalSlideMobile = ({ data, powerButtonRefMobile }: any) => {
  const { verticalSlide, overlayOpacity } = data;

  return (
    <div
      className={`vertical-slide-mobile vertical-slide-section
      ${verticalSlide.length > 1 ? 'vertical-slider-list' : ''}`}
      ref={powerButtonRefMobile}
    >
      <Swiper
        className={`${verticalSlide.length === 1 ? 'home-swiper-wrapper-mobile' : ''
          }`}
        slidesPerView={1.2}
        spaceBetween={20}
        centeredSlides
        modules={[Pagination]}
        parallax
        speed={800}
        grabCursor
        roundLengths
        loopedSlides={verticalSlide.length}
        loop
        pagination={{
          el: '.swiper-pagination',
          clickable: true,
        }}
      >
        {verticalSlide &&
          verticalSlide.length > 1 &&
          verticalSlide.map((item: any) => {
            const {
              id,
              title,
              image,
              tagline,
              summary,
              ctaButton,
              ctaLink,
              columnLayout,
            } = item;
            const Image: any =
              image && image.localFile ? getImage(image.localFile) : null;
            return (
              <SwiperSlide key={id}>
                <div className="vertical-slide-mobile-item">
                  <div className="vertical-slide-mobile-item-image">
                    {Image && <GatsbyImage image={Image} alt={title} />}
                    <div className="vertical-slide-mobile-item-title">
                      {title && <h2>{title}</h2>}
                    </div>
                    {overlayOpacity && (
                      <div
                        className="vertical-slide-item-section showOverlay"
                        style={{
                          opacity: `${overlayOpacity || 0.1}`,
                        }}
                      />
                    )}
                  </div>

                  <div className="vertical-slide-mobile-item-detail container">
                    <div className="vertical-slide-mobile-item-detail-wrapper">
                      <div className="vertical-slide-mobile-item-content">
                        {tagline && (
                          <h5 className="vertical-slide-mobile-item-content-title">
                            {tagline}
                          </h5>
                        )}
                        <div className="vertical-slide-mobile-item-content-wrapper">
                          {summary && (
                            <div
                              className={
                                ctaButton
                                  ? 'vertical-slide-mobile-item-content-inner vertical-slide-item-content-cta-button'
                                  : 'vertical-slide-mobile-item-content-inner full-width'
                              }
                            >
                              <div
                                className={`vertical-slide-mobile-item-content-description 
                                ${columnLayout === 'twoColumn'
                                    ? 'twoColumn'
                                    : ''
                                  }`}
                              >
                                <ReactMarkdown className="htmlcontent">
                                  {summary.data.summary}
                                </ReactMarkdown>
                              </div>
                            </div>
                          )}
                          {ctaButton && (
                            <div className="vertical-slide-mobile-item-button">
                              <CTALink ctaLink={ctaLink}>
                                {ctaButton}
                                <ReadmoreArrow />
                              </CTALink>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        <div className="swiper-pagination" />
      </Swiper>

      {verticalSlide &&
        verticalSlide.length === 1 &&
        verticalSlide.map((item: any) => {
          const {
            id,
            title,
            image,
            tagline,
            summary,
            ctaButton,
            ctaLink,
            columnLayout,
          } = item;
          const Image: any =
            image && image.localFile ? getImage(image.localFile) : null;
          return (
            <div className="vertical-slide-mobile-item one" key={id}>
              <div className="vertical-slide-mobile-item-image">
                {Image && <GatsbyImage image={Image} alt={title} />}
                <div className="vertical-slide-mobile-item-title">
                  {title && <h2>{title}</h2>}
                </div>
                {overlayOpacity && (
                  <div
                    className="vertical-slide-item-section showOverlay"
                    style={{
                      opacity: `${overlayOpacity || 0.1}`,
                    }}
                  />
                )}
              </div>

              <div className="vertical-slide-mobile-item-detail container">
                <div className="vertical-slide-mobile-item-detail-wrapper">
                  <div className="vertical-slide-mobile-item-content">
                    {tagline && (
                      <h5 className="vertical-slide-mobile-item-content-title">
                        {tagline}
                      </h5>
                    )}
                    <div className="vertical-slide-mobile-item-content-wrapper">
                      {summary && (
                        <div
                          className={
                            ctaButton
                              ? 'vertical-slide-mobile-item-content-inner vertical-slide-item-content-cta-button'
                              : 'vertical-slide-mobile-item-content-inner full-width'
                          }
                        >
                          <div
                            className={`vertical-slide-mobile-item-content-description 
                                ${columnLayout === 'twoColumn'
                                ? 'twoColumn'
                                : ''
                              }`}
                          >
                            <ReactMarkdown className="htmlcontent">
                              {summary.data.summary}
                            </ReactMarkdown>
                          </div>
                        </div>
                      )}
                      {ctaButton && (
                        <div className="vertical-slide-mobile-item-button">
                          <CTALink ctaLink={ctaLink}>
                            {ctaButton}
                            <ReadmoreArrow />
                          </CTALink>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
