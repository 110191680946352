/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface SlideInLeftProps {
  children: any;
  className?: string;
  durationTime?: number;
  direction?: string;
}

export const SlideInAnimation: FC<SlideInLeftProps> = ({
  children,
  className,
  durationTime,
  direction,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const element: any = ref.current;

    const animationConfig = {
      opacity: 1,
      x: 0,
      y: 0,
      rotateX: 0,
      duration: durationTime ?? 0.5,
      scrollTrigger: {
        trigger: element,
        start: 'top bottom-=250',
        toggleActions: 'play none none reverse',
      },
    };
    const animationfrom = {
      x: 0,
      y: 0,
      opacity: 0,
    };
    if (direction === 'right') {
      animationfrom.x = 100;
    } else {
      animationfrom.x = -100;
    }

    gsap.fromTo(element, animationfrom, animationConfig);
  }, []);

  return (
    <div ref={ref} className={className ?? className}>
      {children}
    </div>
  );
};
