import { useStaticQuery, graphql } from 'gatsby';

const BrandLogoSlider = () => {
  return useStaticQuery(graphql`
    query BrandLogoSlider {
      strapiGlobal {
        brandLogo {
          id
          image {
            name
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);
};

export { BrandLogoSlider };
