import React, { FC } from 'react';
import { Header, Footer, BrandSlider } from 'components';
import 'sass/style.scss';
// Swiper Js Styling
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import { MainNav, BrandLogoSlider } from 'queries';

export interface LayoutProps {
  children: any;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const data = MainNav();
  const brandSliderData = BrandLogoSlider();
  const header = data?.strapiGlobal;
  const footer = data?.strapiGlobal?.footer;
  const socialMediaLinks = data?.strapiGlobal?.socialMediaLinks;
  const brandSliderList = brandSliderData.strapiGlobal.brandLogo;
  return (
    <>
      <Header headerNav={header} />
      <main>{children}</main>
      <BrandSlider data={brandSliderList} />
      <Footer footer={footer} socialMediaLinks={socialMediaLinks} />
    </>
  );
};

export default Layout;
