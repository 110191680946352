import React, { useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import ReadmoreArrow from 'assets/svg/readmore.svg';
import { Link } from 'gatsby';

export const SingleCardSection = ({
  title,
  summary,
  image,
  bgColor,
  twoColumns,
}: any) => {
  gsap.registerPlugin(ScrollTrigger);
  const trigger = useRef();
  useEffect(() => {
    gsap.from('.single-card-section-card', {
      width: 0,
      stagger: 0.2,
      ease: 'power2.inOut',
      scrollTrigger: {
        trigger: trigger.current,
        start: 'top 70%',
      },
    });
    gsap.from('.single-card-section-card > *', {
      opacity: 0,
      x: 50,
      stagger: 0.2,
      delay: 0.2,
      ease: 'easeOut',
      scrollTrigger: {
        trigger: trigger.current,
        start: 'top 70%',
      },
    });
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 1500);
  }, []);
  return (
    <div className="single-card-section" ref={trigger}>
      <div
        className={`single-card-section-container 
        ${image ? 'single-card-section-image-width' : ''} `}
      >
        {image && (
          <div
            className="single-card-section-image"
            style={{ backgroundImage: `url(${image.localFile.publicURL})` }}
          />
        )}
        <div
          className={`single-card-section-card 
          ${twoColumns ? 'width-two-column' : ''} 
          background-${bgColor}
          `}
        >
          <div
            className={`single-card-section-card-content text-${bgColor}
          `}
          >
            {title && (
              <h3
                className={`single-card-section-card-content-title 
                ${image ? 'title-img-bg' : ''} 
                ${twoColumns ? 'title-two-column' : ''}`}
              >
                {title}
              </h3>
            )}
            <div
              className={`single-card-section-card-content-desc summary-${bgColor} ${
                image ? 'content-img-bg' : ''
              }`}
            >
              {summary && (
                <ReactMarkdown
                  className={`single-card-section-card-content-tagline
                  ${twoColumns ? 'tagline-two-column' : ''}`}
                >
                  {summary}
                </ReactMarkdown>
              )}
              {image && (
                <div className="single-card-section-cta">
                  <Link to="/contact-us" className="">
                    Contact Us
                    <ReadmoreArrow />
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
