/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { GreenCard } from 'components';
import ReactMarkdown from 'react-markdown';

const WhatWeDoBanner = ({ BannerContent, pageHeading }: any) => {
  const { bannerContent, slug } = BannerContent;
  const { description } = bannerContent.description.data;
  const { tagline } = bannerContent.tagline.data;
  const { serviceList } = bannerContent.serviceList.data;
  const { bannerSummary } = bannerContent;

  return (
    <div className="what-we-do-Banner-container banner-left-radius">
      <div className="what-we-do-Banner-wrapper">
        {pageHeading && <h1 className="page-heading">{pageHeading}</h1>}
        <h3 className="what-we-do-Banner-Tagline">{tagline}</h3>
        <div className="what-we-do-Banner-description">
          <ReactMarkdown className="htmlcontent">{description}</ReactMarkdown>
        </div>
      </div>
      <div className="what-we-do-Banner-list">
        <ReactMarkdown className="htmlcontent">{serviceList}</ReactMarkdown>
        <GreenCard Summary={bannerSummary} pageTitle={slug} />
      </div>
    </div>
  );
};

export { WhatWeDoBanner };
