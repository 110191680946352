import React from 'react';

export const FilterMobile = ({ filters, handleClick, isFilterOpen }) => {
  return (
    <div className="filter-mobile">
      <div className="filter-mobile-wrapper">
        <ul>
          {filters &&
            filters.length > 0 &&
            filters.map((filterItem: any) => {
              return (
                <li
                  key={filterItem.title}
                  className={`${isFilterOpen ? 'active' : ''}`}
                >
                  {filterItem && filterItem.title && (
                    <button
                      type="button"
                      className={`${filterItem.sectionTheme || ''}`}
                      onClick={() => {
                        handleClick(filterItem.title);
                      }}
                    >
                      {filterItem.title}
                    </button>
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};
