import React from 'react';

export const IndustryBanner = ({
  bgColor,
  textColor,
  captionColor,
  BannerContent,
  pageHeading,
}: any) => {
  const Summary = BannerContent?.bannerContent?.bannerSummary || '';

  return (
    <div className={`banner bg-${bgColor}`}>
      <div className="banner-wrapper">
        <div className="banner-content">
          {pageHeading && <h1 className="page-heading">{pageHeading}</h1>}
          <h3
            className={`banner-caption banner-caption-industrybanner font-lg text-${captionColor}`}
          >
            {BannerContent?.bannerContent?.description.data.description}
          </h3>
          <div className="banner-content-tagline">
            {BannerContent?.bannerContent?.tagline.data.tagline}
          </div>
          <div className="banner-content-info banner-content-info-industrybanner">
            {Summary &&
              Summary.length > 0 &&
              Summary.map((item: any) => {
                const { title, summary } = item;
                return (
                  <div
                    className={`banner-content-wrapper banner-content-wrapper-industrybanner font-body text-${textColor}`}
                    key={title}
                  >
                    {title && (
                      <div className="banner-content-title">{title}</div>
                    )}
                    {summary.data.summary && (
                      <div className="banner-content-description">
                        {summary.data.summary}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustryBanner;
