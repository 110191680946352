/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ButtonHoverAnimation, CTALink } from 'components';

const BannerSlider = ({ heroBanner }: any) => {
  const { banner, speed } = heroBanner;
  const delay = speed;
  const lineRef = useRef(null) as any;

  const progress = () => {
    let width = 1;
    const autoplayTime = delay / 100;
    function frame() {
      if (width >= 100) {
        clearInterval(timeLength);
      } else if (lineRef.current) {
        width++;
        lineRef.current.style.width = `${width}%`;
      }
    }
    const timeLength = setInterval(frame, autoplayTime);
  };

  return (
    <div className="banner-slider-container">
      <Swiper
        navigation
        slidesPerView={1}
        speed={1200}
        modules={[Autoplay]}
        autoplay={{
          delay,
          disableOnInteraction: false,
        }}
        loop
        onSlideChange={progress}
        className="banner-swiper"
      >
        {banner.map((item: any) => {
          const {
            ctaLink,
            ctaText,
            tagline,
            title,
            id,
            imageMobile,
            imageDesktop,
            videoMobile,
            videoDesktop,
            showOverlay,
            active,
            overlayOpacity,
          } = item;
          const BannerImage: any =
            imageMobile && imageMobile.localFile
              ? getImage(imageMobile.localFile.childImageSharp?.gatsbyImageData)
              : null;
          const BannerImageDesktop: any =
            imageDesktop && imageDesktop.localFile
              ? getImage(
                  imageDesktop.localFile.childImageSharp?.gatsbyImageData
                )
              : null;
          if (!active) {
            return null;
          }

          return (
            <SwiperSlide key={id}>
              <div className="banner-slider">
                {videoDesktop ? (
                  <div className="banner-slider-Media-wrapper">
                    <video autoPlay muted>
                      <source
                        src={videoDesktop?.localFile?.publicURL}
                        type="video/mp4"
                      />
                    </video>
                    {showOverlay && (
                      <div
                        className="banner-slider-section showOverlay"
                        style={{ opacity: `${overlayOpacity || 0.5}` }}
                      />
                    )}
                  </div>
                ) : (
                  <div className="banner-slider-Media-wrapper">
                    <GatsbyImage
                      image={BannerImageDesktop}
                      alt={(imageDesktop?.alternativeText && title) || ''}
                    />
                    {showOverlay && (
                      <div
                        className="banner-slider-section showOverlay"
                        style={{ opacity: `${overlayOpacity || 0.5}` }}
                      />
                    )}
                  </div>
                )}

                {videoMobile ? (
                  <div className="banner-slider-Media-responsive-wrapper">
                    <video autoPlay muted>
                      <source
                        src={videoMobile?.localFile?.publicURL}
                        type="video/mp4"
                      />
                    </video>
                    {showOverlay && (
                      <div
                        className="banner-slider-section showOverlay"
                        style={{ opacity: `${overlayOpacity || 0.5}` }}
                      />
                    )}
                  </div>
                ) : (
                  <div className="banner-slider-Media-responsive-wrapper">
                    <GatsbyImage
                      image={BannerImage}
                      alt={(imageMobile?.alternativeText && title) || ''}
                    />
                    {showOverlay && (
                      <div
                        className="banner-slider-section showOverlay"
                        style={{ opacity: `${overlayOpacity || 0.5}` }}
                      />
                    )}
                  </div>
                )}
                <div className="banner-slider-inner-container">
                  <div className="banner-slider-inner">
                    {title && <p className="banner-slider-title">{title}</p>}
                    <ButtonHoverAnimation scaleFrom={1.05} scaleTo={1.15}>
                      {ctaLink && (
                        <div className="banner-slider-cta">
                          <CTALink ctaLink={ctaLink}>{ctaText}</CTALink>
                        </div>
                      )}
                    </ButtonHoverAnimation>
                  </div>
                  {tagline && (
                    <div className="banner-slider-inner-tagline">{tagline}</div>
                  )}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
        <div className="progress-line" ref={lineRef} />
      </Swiper>
    </div>
  );
};

export { BannerSlider };
