import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { BlogCard } from 'components/BlogCard';

const BlogSlider = ({ data }: any) => {
  const sliderList = useStaticQuery(graphql`
    {
      strapiBlogPage {
        slug
      }
      allStrapiArticle(sort: { date: DESC }, limit: 10) {
        edges {
          node {
            article_tag {
              title
              sectionTheme
            }
            title
            date
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            slug
            id
          }
        }
      }
    }
  `);
  return (
    <div className="blog-slider-wrapper">
      <div className="container">
        <div className="blog-slider-heading">
          {data && data.title && <h2>{data.title}</h2>}
        </div>
        {data && data.summary && (
          <div className="blog-slider-description">
            <p>{data.summary?.data?.summary}</p>
          </div>
        )}
      </div>
      <div className="blog-slider">
        <Swiper
          navigation
          slidesPerView={1.15}
          spaceBetween={20}
          breakpoints={{
            592: {
              slidesPerView: 2,
              spaceBetween: 15,
              centeredSlides: false,
            },
            1024: {
              slidesPerView: 2.5,
              spaceBetween: 18,
              centeredSlides: false,
            },
            1400: {
              slidesPerView: 3.5,
              spaceBetween: 25,
              centeredSlides: false,
            },
            2000: {
              slidesPerView: 5,
              spaceBetween: 30,
              centeredSlides: false,
            },
          }}
          modules={[Navigation]}
        >
          {sliderList &&
            sliderList.allStrapiArticle &&
            sliderList.allStrapiArticle.edges.map((item: any) => {
              return (
                <SwiperSlide key={item.node.id}>
                  <BlogCard
                    item={item.node}
                    parentUrl={sliderList.strapiBlogPage.slug}
                  />
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </div>
  );
};

export { BlogSlider };
