import React, { FC, useRef } from 'react';
import { Parallax } from 'react-scroll-parallax';

interface ParallaxEffectProps {
  children: any;
  className?: string;
  translateY?: any;
}
export const ParallaxEffect: FC<ParallaxEffectProps> = ({
  children,
  className,
  translateY,
  scale,
}) => {
  const ref = useRef(null);
  return (
    <Parallax
      speed={-15}
      opacity={[0.9, 1]}
      translateY={translateY || [-20, 10]}
      scale={scale || [1, 1]}
      className={className ?? className}
    >
      <div ref={ref}>{children}</div>
    </Parallax>
  );
};
