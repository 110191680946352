/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

export const NumbersAnimation = ({ children }: any) => {
  gsap.registerPlugin(ScrollTrigger);
  const trigger = useRef(null);
  const numberRef = useRef([]);
  const splitChars = (st: string) => {
    return st.toString().split('');
  };
  const charsArray = splitChars(children).map(num => {
    if (Number.isNaN(num)) {
      return [' ', num];
    }
    if (num === '0') {
      return ['9', num];
    }
    if (num === ',') {
      return [',', num];
    }
    return [num - 1, num];
  });

  const addNumberRefs = (el: any) => {
    if (el && !numberRef.current.includes(el as never)) {
      numberRef.current.push(el as never);
    }
  };

  useEffect(() => {
    const scrollNumber = window.innerWidth < 990 ? 170 : 280;
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: trigger.current,
        toggleActions: 'play none none reset',
      },
    });
    tl.fromTo(
      numberRef.current,
      { y: scrollNumber, stagger: 0.3, ease: 'expo' },
      { y: 0, stagger: 0.3, ease: 'expo' },
      '<'
    );
  }, []);

  return (
    <div className="process-numbers-animation" ref={trigger}>
      {charsArray.map((chars, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div
          key={index}
          ref={addNumberRefs}
          aria-hidden
          className="process-numbers-animation-item"
        >
          {chars.map((item, i) => (
            <span key={i} aria-hidden>
              {item}
            </span>
          ))}
        </div>
      ))}
    </div>
  );
};
