/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Arrow from 'assets/svg/Arrow.svg';
import { GatsbyImage } from 'gatsby-plugin-image';

const OurFamilyDesktop = ({ data, handlerMemberSelection }: any) => {
  const peopleData = data.allStrapiPeople.nodes;
  peopleData.sort((a, b) => (a.name > b.name ? 1 : -1));
  return (
    <div className="people-page-family-desktop">
      {peopleData.map((item: any) => {
        const { name, image, id } = item;
        return (
          <div className="people-page-family-content" key={id}>
            <div className="people-page-family-content-list">
              <div
                className="people-page-family-content-img"
                onClick={() => handlerMemberSelection(item)}
              >
                {image?.localFile?.childImageSharp?.gatsbyImageData && (
                  <GatsbyImage
                    image={image?.localFile?.childImageSharp?.gatsbyImageData}
                    alt={name}
                  />
                )}
              </div>
              <div className="people-page-family-content-list-item">
                <h3 className="people-page-family-content-list-title">
                  {name}
                </h3>
                <button
                  type="button"
                  onClick={() => handlerMemberSelection(item)}
                  className="people-page-family-content-list-link"
                >
                  Read more
                  <span>
                    <Arrow />
                  </span>
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { OurFamilyDesktop };
