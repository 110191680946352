import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

export const SupplyChain = ({ data }: any) => {
  const { title, tagline, summary, image } = data;
  return (
    <div className="supply-chain bg-offwhite">
      <div className="supply-chain-content ">
        <h2 className="supply-chain-content-title font-body text-pink">
          {title}
        </h2>
        <h3 className="supply-chain-content-tagline card-title-sm text-green bold">
          {tagline}
        </h3>
        <div className="supply-chain-image mobile">
          {image?.localFile?.childImageSharp?.gatsbyImageData && (
            <GatsbyImage
              image={image?.localFile?.childImageSharp?.gatsbyImageData}
              alt={title}
            />
          )}
        </div>
        <div className="supply-chain-content-info font-body text-darkgreen">
          {summary.data.summary && (
            <div className="supply-chain-content-info-content">
              <ReactMarkdown className="htmlcontent">
                {summary.data.summary}
              </ReactMarkdown>
            </div>
          )}
        </div>
      </div>
      <div className="supply-chain-image desktop">
        {image?.localFile?.childImageSharp?.gatsbyImageData && (
          <GatsbyImage
            image={image?.localFile?.childImageSharp?.gatsbyImageData}
            alt={title}
          />
        )}
      </div>
    </div>
  );
};
