import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SlideInAnimation } from 'components';

const FamilyHistory = ({ familyHistory }: any) => {
  const { title, tagline, description } = familyHistory;

  return (
    <div className="family-history-wrapper">
      <div className="family-history-title">{title}</div>
      <h3 className="family-history-description">{tagline}</h3>
      <div className="family-history-desc-wrapper">
        <div className="family-history-shortDescription">
          {description.data.description}
        </div>
        <div className="family-history-container">
          {familyHistory.history.map((item: any) => {
            const {
              title: familyTitle,
              description: familyDescription,
              image,
              year,
              yearPosition,
              imagePosition,
            } = item;
            const textAnimationDirection =
              imagePosition === 'left' ? 'right' : 'left';
            const imageAnimation = imagePosition === 'right' ? 'right' : 'left';
            const yearAnimation = imagePosition === 'right' ? 'right' : 'left';
            return (
              <div
                key={familyTitle}
                className={`family-history-details-container image-${imagePosition}`}
              >
                <div className="family-history-image">
                  <div className="family-history-image-wrapper">
                    {image?.localFile?.childImageSharp?.gatsbyImageData && (
                      <SlideInAnimation direction={imageAnimation}>
                        <GatsbyImage
                          image={
                            image?.localFile?.childImageSharp?.gatsbyImageData
                          }
                          alt={familyTitle || ''}
                        />
                      </SlideInAnimation>
                    )}
                    {yearPosition === 'on image' && (
                      <SlideInAnimation
                        direction={yearAnimation}
                        className="family-history-year"
                      >
                        {year}
                      </SlideInAnimation>
                    )}
                  </div>
                </div>

                <div className="family-history-details">
                  {yearPosition === 'above text' && (
                    <SlideInAnimation
                      direction={textAnimationDirection}
                      className="family-history-year"
                    >
                      {year}
                    </SlideInAnimation>
                  )}
                  <SlideInAnimation direction={textAnimationDirection}>
                    <div className="family-history-details-tagline">
                      {familyTitle}
                    </div>
                    <div className="family-history-details-summary">
                      {familyDescription.data.description}
                    </div>
                  </SlideInAnimation>
                  <div className="family-history-line" />
                  <div className="family-history-circle">
                    <div className="family-history-point" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div />
    </div>
  );
};

export { FamilyHistory };
