import React from 'react';
import Frame from 'assets/svg/Frame.svg';
import ReadArrow from 'assets/svg/readarrow.svg';
import ReactMarkdown from 'react-markdown';

const BannerProductRange = ({ BannerContent, pageHeading }: any) => {
  const { tagline } = BannerContent.bannerContent;
  const { description } = BannerContent.bannerContent.description.data;
  const { content } = BannerContent.bannerContent.content.data;
  const DownloadText = BannerContent.bannerContent.downloadProductTitle;
  const DownloadFile =
    BannerContent.bannerContent.downloadProductPDF?.localFile?.publicURL;

  return (
    <div className="our-product-range-container">
      {pageHeading && <h1 className="page-heading">{pageHeading}</h1>}
      {tagline && <h3 className="our-product-range-Description">{tagline}</h3>}
      <div className="our-product-range-wrapper">
        <div className="our-product-range-summary-wrapper">
          {description && (
            <div className="our-product-range-summary">{description}</div>
          )}
          {DownloadText && (
            <a
              href={DownloadFile}
              download="Product-list"
              className="our-product-range-link"
            >
              {DownloadText}
              <ReadArrow />
            </a>
          )}
          <div className="our-product-range-enquiry-wrapper">
            {content && (
              <div className="our-product-range-enquiry">
                <ReactMarkdown className="htmlcontent">{content}</ReactMarkdown>
              </div>
            )}
          </div>
        </div>
        <div className="our-product-range-svg-wrapper">
          <Frame />
        </div>
      </div>
    </div>
  );
};

export { BannerProductRange };
