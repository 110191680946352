import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SlideInAnimation } from 'components';

const FamilyHistoryResponsive = ({ familyHistory }: any) => {
  const { title, tagline, description } = familyHistory;

  return (
    <div className="family-history-responsive-wrapper">
      <div className="family-history-responsive-title">{title}</div>
      <div className="family-history-responsive-description">{tagline}</div>
      <div className="family-history-responsive-shortDescription">
        {description.data.description}
      </div>
      <div className="family-history-responsive-container">
        {familyHistory.history.map((item: any, index: number) => {
          const {
            title: familyTitle,
            description: familyDescription,
            image,
            year,
          } = item;

          return (
            <div
              className="family-history-responsive-details-wrapper"
              key={familyTitle}
            >
              <div className="family-history-responsive-line-container">
                <div className="family-history-responsive-line" />
                <div className="family-history-responsive-point" />
              </div>
              <SlideInAnimation direction={index === 1 ? 'right' : 'left'}>
                <div className="family-history-responsive-inner-details-container">
                  <div className="family-history-responsive-inner-details-Image">
                    {image?.localFile?.childImageSharp?.gatsbyImageData && (
                      <GatsbyImage
                        image={
                          image?.localFile?.childImageSharp?.gatsbyImageData
                        }
                        alt={familyTitle || ''}
                      />
                    )}
                  </div>
                  <div className="family-history-responsive-inner-details">
                    <div className="family-history-responsive-inner-details-year">
                      {year}
                    </div>
                    <div className="family-history-responsive-inner-details-tagline">
                      {familyTitle}
                    </div>
                    <div className="family-history-responsive-inner-details-summary">
                      {familyDescription.data.description}
                    </div>
                  </div>
                </div>
              </SlideInAnimation>
            </div>
          );
        })}
      </div>

      <div className="family-history-responsive-bottom-line" />
    </div>
  );
};

export { FamilyHistoryResponsive };
