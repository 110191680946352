import React from 'react';
import DownLoadIcon from 'assets/svg/Download-icon.svg';

export const BannerInfo = ({
  bgColor,
  className,
  textColor,
  captionColor,
  BannerContent,
  powerButtonRef,
  powerOfOneButton,
  powerButtonRefMobile,
  pageHeading,
}: any) => {
  const Summary =
    BannerContent &&
    BannerContent.bannerContent &&
    BannerContent.bannerContent.bannerSummary;
  const description =
    BannerContent &&
    BannerContent.bannerContent &&
    BannerContent.bannerContent.description;
  const downloadButton =
    BannerContent &&
    BannerContent.downloadBrandText &&
    BannerContent.downloadBrandText;
  const DownloadFile = BannerContent?.downloadBrandPDF?.localFile?.publicURL;
  const scrollPlan = () => {
    if (powerButtonRef.current || powerButtonRefMobile.current) {
      powerButtonRef.current.scrollIntoView({ behavior: 'smooth' });
      powerButtonRefMobile.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={`banner bg-${bgColor} ${!!className && className}`}>
      <div className="banner-wrapper">
        <div className="banner-content">
          {pageHeading && <h1 className="page-heading">{pageHeading}</h1>}
          {description && description.data && description.data.description && (
            <h3 className={`banner-caption font-lg text-${captionColor}`}>
              {description.data.description}
            </h3>
          )}
          <div className="banner-summary-wrapper">
            <div className="banner-content-info">
              {Summary &&
                Summary.length &&
                Summary.map((item: any) => {
                  const { title, summary } = item;
                  return (
                    <div
                      className={`banner-content-wrapper font-body text-${textColor}`}
                      key={title}
                    >
                      {title && (
                        <h5 className="banner-content-title">{title}</h5>
                      )}
                      {summary && summary.data && summary.data.summary && (
                        <div className="banner-content-description">
                          {summary.data.summary}
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
            {downloadButton && (
              <div className="banner-download-btn-wrapper">
                <div className="banner-download-btn">
                  <a
                    href={DownloadFile}
                    download="TastyOne Brochure"
                    className="download-link"
                  >
                    <span>{downloadButton}</span>

                    <DownLoadIcon className="download-icon" />
                  </a>
                </div>
              </div>
            )}
          </div>
          {powerOfOneButton && (
            <button
              type="button"
              className="banner-power-btn"
              onClick={() => scrollPlan()}
            >
              {powerOfOneButton}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
