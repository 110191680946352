import React, { useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import NavLogo from 'assets/svg/menu-logo.svg';
import gsap from 'gsap';
import Facebook from 'assets/svg/facebook.svg';
import Instagram from 'assets/svg/instagram.svg';
import LinkedIn from 'assets/svg/linkedin.svg';

const Menu = ({ closeMenu, isMenuOpen, header, socialMediaLinks }: any) => {
  const revealRefs = useRef([]);
  const LinkRefs = useRef([]);
  const addRevealRefs = el => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };
  const addLinkRefs = el => {
    if (el && !LinkRefs.current.includes(el)) {
      LinkRefs.current.push(el);
    }
  };

  useEffect(() => {
    const MenuTimeline = gsap.timeline();
    if (isMenuOpen) {
      MenuTimeline.from(revealRefs.current, {
        y: 30,
        opacity: 0,
        stagger: 0.2,
      });
      MenuTimeline.from(LinkRefs.current, {
        x: 30,
        display: 'inline-block',
        opacity: 0,
        stagger: 0.1,
      });
      MenuTimeline.from(
        '.menu-mobile-socialmedia',
        {
          x: 30,
          opacity: 0,
          stagger: 0.1,
        },
        '<'
      );
    }
  }, [isMenuOpen]);
  return (
    <div className="menu-list-wrapper">
      <ul className="menu-list">
        {header.mainNav.map((nav: any) => (
          <li className="menu-list-item" key={nav.slug} ref={addRevealRefs}>
            <Link
              to={
                (nav.slug && nav.slug.startsWith('/')
                  ? `${nav.slug}`
                  : `/${nav.slug}`) || '/'
              }
              onClick={closeMenu}
            >
              {nav.title}
            </Link>
          </li>
        ))}
      </ul>
      <div className="menu-list-separator" ref={addRevealRefs} />
      <div className="menu-footer-list-wrapper">
        <div className="menu-footer-list">
          {header.headerLinks.map((item: any) => {
            return (
              <div className="menu-footer-list-item" key={item.slug}>
                <Link
                  to={
                    (item.slug && item.slug.startsWith('/')
                      ? `${item.slug}`
                      : `/${item.slug}`) || '/'
                  }
                  onClick={closeMenu}
                  ref={addLinkRefs}
                >
                  {item.title}
                </Link>
              </div>
            );
          })}
          <div className="menu-footer-list-item">
            <a
              href={`tel:${header.phoneNumber}`}
              onClick={closeMenu}
              ref={addLinkRefs}
            >
              {header.phoneText}
            </a>
          </div>
        </div>
        <div className="menu-logo">
          <Link to="/" ref={addLinkRefs} onClick={closeMenu}>
            <NavLogo />
          </Link>
        </div>
      </div>
      <div className="header-list-social-media menu-mobile-socialmedia">
        <a
          href={socialMediaLinks?.facebookLink}
          className="header-media-link"
          onClick={closeMenu}
        >
          <Facebook />
        </a>
        <a
          href={socialMediaLinks?.instagramLink}
          className="header-media-link"
          onClick={closeMenu}
        >
          <LinkedIn />
        </a>
        <a
          href={socialMediaLinks?.linkedinLink}
          className="header-media-link"
          onClick={closeMenu}
        >
          <Instagram />
        </a>
      </div>
    </div>
  );
};

export { Menu };
