import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';
import { ParallaxEffect } from 'components';

const BannerForwardThinking = ({ bannerContent, pageHeading }: any) => {
  const description = bannerContent.bannerDescription.tagline.data.tagline;
  const tagline = bannerContent.bannerDescription.bannerSummary;

  const BannerContentImage: any =
    bannerContent.image && bannerContent.image.localFile
      ? getImage(bannerContent.image.localFile.childImageSharp?.gatsbyImageData)
      : null;

  return (
    <div className="forward-banner-details-container container">
      <div className="forward-banner-details-wrapper">
        {pageHeading && <h1 className="page-heading">{pageHeading}</h1>}

        {description && (
          <h3 className="forward-banner-details-tagline">{description}</h3>
        )}
        <div className="forward-banner-content">
          {tagline.map((item: any) => {
            const { title, summary } = item;
            return (
              <div className="forward-banner-content-wrapper" key={title}>
                {title && (
                  <div className="forward-banner-content-title">{title}</div>
                )}
                {summary.data.summary && (
                  <div className="forward-banner-content-description">
                    <ReactMarkdown className="htmlcontent">
                      {summary.data.summary}
                    </ReactMarkdown>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="forward-banner-details-Image-wrapper">
        <div className="forward-banner-details-Image">
          <ParallaxEffect>
            <GatsbyImage
              image={BannerContentImage}
              alt={(bannerContent.image?.alternativeText && description) || ''}
            />
          </ParallaxEffect>
        </div>
      </div>
    </div>
  );
};

export { BannerForwardThinking };
