/* eslint-disable camelcase */
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Close from 'assets/svg/ModalClose.svg';
import ReactMarkdown from 'react-markdown';

const ProductListDetails = ({ closeModal, ProductInfo }: any) => {
  const {
    title,
    color,
    description,
    weight,
    seasonality,
    popupImage,
    product_cuts,
    cutsTitle,
  } = ProductInfo;
  const ProductCuts = product_cuts;
  return (
    <div className="product-details-container">
      <div className="product-details-heading-wrapper">
        <div className="button-wrapper">
          <button type="button" className="close-button" onClick={closeModal}>
            <Close />
          </button>
        </div>
        <h2 className="product-details-heading font-xl">
          {title} {color && <span>({color})</span>}
        </h2>
      </div>
      <div className="product-details-section-wrapper">
        <div className="product-details-section">
          <div className="product-details-content">
            {description.data.description && (
              <div className="product-details-item">
                <div className="product-details-item-summary">
                  <ReactMarkdown className="htmlcontent">
                    {description.data.description}
                  </ReactMarkdown>
                </div>
              </div>
            )}
            {weight.data.weight && (
              <div className="product-details-item">
                <div className="product-details-item-summary">
                  <ReactMarkdown className="htmlcontent">
                    {weight.data.weight}
                  </ReactMarkdown>
                </div>
              </div>
            )}
            {seasonality.data.seasonality && (
              <div className="product-details-item">
                <div className="product-details-item-summary">
                  <ReactMarkdown className="htmlcontent">
                    {seasonality.data.seasonality}
                  </ReactMarkdown>
                </div>
              </div>
            )}

            <div className="product-details-section-cuts-container" />
          </div>
          <div className="product-details-Image-wrapper">
            {popupImage?.localFile?.childImageSharp?.gatsbyImageData && (
              <GatsbyImage
                image={popupImage?.localFile?.childImageSharp?.gatsbyImageData}
                alt={title}
              />
            )}
          </div>
        </div>
        <div className="product-details-section">
          {ProductCuts.length > 0 && (
            <div className="product-details-item cuts">
              <div className="product-details-item-summary">
                {cutsTitle && <h2>{cutsTitle}</h2>}
              </div>
              <ul className="product-details-cuts">
                {ProductCuts.length > 0 &&
                  ProductCuts.map((cut: any) => {
                    return (
                      <li className="product-details-cuts-tag">{cut.title}</li>
                    );
                  })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { ProductListDetails };
