import React, { useState } from 'react';
import { Link } from 'gatsby';
import Arrow from 'assets/svg/readarrow.svg';
import { Parallax } from 'react-scroll-parallax';
import { GatsbyImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';
import { ModalComponent, PeopleBio } from 'components';

export const OurExperts = ({ expertsData }: any) => {
  const [isModal, setisModal] = useState(false);
  const closeModal = () => {
    setisModal(false);
  };
  const [selectedMember, setSelectedMember] = useState({});

  const handlerMemberSelection = (name: String) => {
    const filteredPeopleBio = expertsData.biographies.find((bio: any) => {
      return bio.name === name;
    });
    const bioData = {
      id: filteredPeopleBio.id,
      name: filteredPeopleBio.name,
      connectWithMeText: filteredPeopleBio.connectWithMeText,
      image: filteredPeopleBio.profileImage,
      tagline: filteredPeopleBio.tagline,
      description: filteredPeopleBio.description,
      socialMediaLinks: filteredPeopleBio.socialMediaLinks,
    };
    setSelectedMember(bioData);
    setisModal(true);
  };

  return (
    <div className="our-experts">
      <Parallax translateY={[-5, 0]} speed={-5} className="wrapper">
        {expertsData.biographies.map((bio: any, index: number) => {
          const { name, image, mobileImage, summary, readMoreText } = bio;
          const wrapperClass = index === 0 ? 'our-experts-wrapper' : 'relative';
          const contentClass = index === 1 ? 'our-experts-card-class' : '';
          const imgClass = index === 1 ? 'fullwidth' : '';

          return (
            <div className={wrapperClass} key={name}>
              <div
                className={`our-experts-img-wrapper ${imgClass} desktop-only`}
              >
                {image?.localFile?.childImageSharp?.gatsbyImageData && (
                  <GatsbyImage
                    image={image?.localFile?.childImageSharp?.gatsbyImageData}
                    alt=""
                  />
                )}
              </div>
              <div
                className={`our-experts-img-wrapper ${imgClass} mobile-only`}
              >
                {mobileImage?.localFile?.childImageSharp?.gatsbyImageData && (
                  <GatsbyImage
                    image={
                      mobileImage?.localFile?.childImageSharp?.gatsbyImageData
                    }
                    alt=""
                  />
                )}
              </div>

              <div className={`our-experts-content-wrapper ${contentClass}`}>
                <div className="our-experts-content">
                  {summary.data.summary && (
                    <ReactMarkdown className={`our-experts-content-caption `}>
                      {summary.data.summary}
                    </ReactMarkdown>
                  )}
                  {readMoreText && (
                    <Link
                      to="/forward-thinking"
                      className="our-experts-content-wrapper-cta font-xs"
                      onClick={() => handlerMemberSelection(name)}
                    >
                      {readMoreText}
                      <Arrow />
                    </Link>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </Parallax>
      <ModalComponent isModal={isModal} closeModal={closeModal}>
        <div className="expert-modal">
          <PeopleBio data={selectedMember} closeModal={closeModal} />
        </div>
      </ModalComponent>
    </div>
  );
};
