/* eslint-disable no-nested-ternary */
import {
  FILTER_POST,
  STORE_POST,
  SORT_DESC,
  SORT_ASC,
} from 'Redux/actionTypes';

export const storePost = (data: any) => ({
  type: STORE_POST,
  payload: data,
});

export const filterPosts = (tag: string) => (dispatch: any, getState: any) => {
  const { SortAndFilterReducer } = getState();
  if (tag === 'All') {
    const { searchResults } = SortAndFilterReducer;
    return dispatch({ type: FILTER_POST, payload: searchResults });
  }
  const searchResults = SortAndFilterReducer.searchResults.filter(
    (post: any) => post.article_tag.title.toLowerCase() === tag.toLowerCase()
  );
  return dispatch({ type: FILTER_POST, payload: searchResults });
};

export const sortPostsAsc = () => (dispatch: any, getState: any) => {
  const { SortAndFilterReducer } = getState();
  const sortedPosts = SortAndFilterReducer.posts.sort((a: any, b: any) =>
    a.date < b.date ? -1 : a.date > b.date ? 1 : 0
  );
  dispatch({ type: SORT_ASC, payload: sortedPosts });
};

export const sortPostsDesc = () => (dispatch: any, getState: any) => {
  const { SortAndFilterReducer } = getState();
  const sortedPosts = SortAndFilterReducer.posts.sort((a: any, b: any) =>
    a.date < b.date ? 1 : a.date > b.date ? -1 : 0
  );
  dispatch({ type: SORT_DESC, payload: sortedPosts });
};
