import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import ReadmoreArrow from 'assets/svg/readmore.svg';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { CTALink } from 'components';
import { Pagination } from 'swiper';

const VerticalSlide = ({ data, powerButtonRef }: any) => {
  const { verticalSlide, overlayOpacity } = data;
  return (
    <div
      className={`vertical-slider vertical-slide-section 
      ${verticalSlide.length > 1 ? 'vertical-slider-list' : ''}`}
      ref={powerButtonRef}
    >
      {verticalSlide && verticalSlide.length > 1 && (
        <Swiper
          slidesPerView={1.2}
          spaceBetween={10}
          centeredSlides
          longSwipes
          longSwipesMs={1000}
          speed={800}
          grabCursor
          roundLengths
          loopedSlides={verticalSlide.length}
          loop
          modules={[Pagination]}
          pagination={{
            el: '.swiper-pagination',
            clickable: true,
          }}
        >
          {verticalSlide.map((item: any) => {
            const {
              id,
              title,
              image,
              tagline,
              summary,
              ctaButton,
              ctaLink,
              columnLayout,
            } = item;
            const Image: any =
              image && image.localFile ? getImage(image.localFile) : null;
            return (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <div key={id}>
                {(title || summary.data.summary) && (
                  <SwiperSlide>
                    <div className="vertical-slide-item">
                      {Image && (
                        <div className="vertical-slide-item-image">
                          <GatsbyImage image={Image} alt={title} />
                          {overlayOpacity && (
                            <div
                              className="vertical-slide-item-section showOverlay"
                              style={{
                                opacity: `${overlayOpacity || 0.1}`,
                              }}
                            />
                          )}
                        </div>
                      )}
                      <div className="vertical-slide-item-detail">
                        <div className="vertical-slide-item-detail-wrapper">
                          <div className="vertical-slide-item-title">
                            {title && <h5>{title}</h5>}
                          </div>
                          <div className="vertical-slide-item-content">
                            {tagline && (
                              <h5 className="vertical-slide-item-content-title">
                                {tagline}
                              </h5>
                            )}
                            <div className="vertical-slide-item-content-wrapper">
                              {summary && (
                                <div
                                  className={
                                    ctaButton
                                      ? 'vertical-slide-item-content-inner vertical-slide-item-content-cta-button'
                                      : 'vertical-slide-item-content-inner full-width'
                                  }
                                >
                                  <div
                                    className={`vertical-slide-item-content-description ${
                                      columnLayout == 'twoColumn'
                                        ? 'twoColumn'
                                        : ''
                                    }`}
                                  >
                                    <ReactMarkdown className="htmlcontent">
                                      {summary.data.summary}
                                    </ReactMarkdown>
                                  </div>
                                </div>
                              )}
                              {ctaButton && (
                                <div className="vertical-slide-item-button">
                                  <CTALink ctaLink={ctaLink}>
                                    {ctaButton}
                                    <ReadmoreArrow />
                                  </CTALink>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )}
              </div>
            );
          })}
          <div className="swiper-pagination" />
        </Swiper>
      )}
      {verticalSlide &&
        verticalSlide.length === 1 &&
        verticalSlide.map((item: any) => {
          const {
            id,
            title,
            image,
            tagline,
            summary,
            ctaButton,
            ctaLink,
            columnLayout,
          } = item;
          const Image: any =
            image && image.localFile ? getImage(image.localFile) : null;
          return (
            <div
              className="vertical-slide-item vertical-slide-item-single"
              key={id}
            >
              {Image && (
                <div className="vertical-slide-item-image">
                  <GatsbyImage image={Image} alt={title} />
                  {overlayOpacity && (
                    <div
                      className="vertical-slide-item-section showOverlay"
                      style={{
                        opacity: `${overlayOpacity || 0.1}`,
                      }}
                    />
                  )}
                </div>
              )}
              <div className="vertical-slide-item-detail">
                <div className="vertical-slide-item-detail-wrapper">
                  <div className="vertical-slide-item-title">
                    {title && <h5>{title}</h5>}
                  </div>
                  <div className="vertical-slide-item-content">
                    {tagline && (
                      <h5 className="vertical-slide-item-content-title">
                        {tagline}
                      </h5>
                    )}
                    <div className="vertical-slide-item-content-wrapper">
                      {summary && (
                        <div
                          className={
                            ctaButton
                              ? 'vertical-slide-item-content-inner vertical-slide-item-content-cta-button'
                              : 'vertical-slide-item-content-inner full-width'
                          }
                        >
                          <div
                            className={`vertical-slide-item-content-description ${
                              columnLayout === 'twoColumn' ? 'twoColumn' : ''
                            }`}
                          >
                            <ReactMarkdown className="htmlcontent">
                              {summary.data.summary}
                            </ReactMarkdown>
                          </div>
                        </div>
                      )}
                      {ctaButton && (
                        <div className="vertical-slide-item-button">
                          <CTALink ctaLink={ctaLink}>
                            {ctaButton}
                            <ReadmoreArrow />
                          </CTALink>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export { VerticalSlide };
