import React, { useRef, useEffect } from 'react';
import Arrow from 'assets/svg/readarrow.svg';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper';

export const KeyIndustries = ({
  dynamicSectionsBlock,
  parentSlug,
  hasBgImg = true,
  showLink,
}: any) => {
  const linkRefs = useRef([]);
  const sliderRef = useRef(null);
  const keyIndustryList = dynamicSectionsBlock.industries;
  const addLinkRefs = el => {
    if (el && !linkRefs.current.includes(el)) {
      linkRefs.current.push(el);
    }
  };
  useEffect(() => {
    if (hasBgImg) {
      linkRefs.current.forEach((el: any, index: number) => {
        el.addEventListener('mouseenter', () => {
          sliderRef.current.swiper.slideTo(index);
        });
        el.addEventListener('mouseleave', () => {
          sliderRef.current.swiper.slideTo(0);
        });
      });
    }
  }, []);
  return (
    <div className={`${hasBgImg ? 'bg-image' : 'bg-color'} key-industries`}>
      <div className="key-industries-wrapper">
        {hasBgImg && (
          <div className="key-industries-img">
            <Swiper
              slidesPerView={1}
              speed={1000}
              effect="fade"
              modules={[EffectFade]}
              ref={sliderRef}
              allowTouchMove={false}
              shortSwipes={false}
              longSwipes={false}
            >
              {keyIndustryList.map((item: any) => {
                return (
                  <SwiperSlide key={item.title}>
                    <GatsbyImage
                      image={
                        item.image?.localFile?.childImageSharp?.gatsbyImageData
                      }
                      alt={item.title}
                    />
                    {item.banner.showOverlay && (
                      <div
                        className="key-industries-img showOverlay"
                        style={{
                          opacity: `${item.banner.overlayOpacity || 0.5}`,
                        }}
                      />
                    )}
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        )}

        <div className="key-industries-content">
          <div className="key-industries-intro-wrapper">
            <div className="key-industries-intro">
              <h2 className="key-industries-intro-title">
                {dynamicSectionsBlock.title}
              </h2>
              <p className="key-industries-intro-caption">
                {dynamicSectionsBlock.description.data.description}
              </p>
            </div>
          </div>

          <div className="key-industries-list">
            {keyIndustryList.map((item: any) => {
              const { title, slug } = item;
              if (showLink) {
                return (
                  <Link
                    to={`/${parentSlug}/${slug}`}
                    className="key-industries-list-item"
                    key={title}
                    ref={addLinkRefs}
                  >
                    {title}
                    <Arrow />
                  </Link>
                );
              }
              return (
                <div
                  className="key-industries-list-item"
                  key={title}
                  ref={addLinkRefs}
                >
                  {title}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
