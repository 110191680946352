/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from 'firebase/functions';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { useStaticQuery, graphql } from 'gatsby';
// @ts-ignore
import app from 'gatsby-plugin-firebase-v9.0';

type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: number;
  company: string;
  message: string;
  submit: string;
};

const ContactForm = ({ formType, buttonLabel }) => {
  const [loading, isloading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isValid },
  } = useForm<Inputs>();
  const {
    site: {
      siteMetadata: { recaptchaKey },
    },
  } = contactFormQuery();
  const onSubmit = (data: any) => {
    const functions = getFunctions(app, 'australia-southeast1');
    initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider(recaptchaKey),
      isTokenAutoRefreshEnabled: true,
    });

    if (process.env.NODE_ENV === 'development')
      connectFunctionsEmulator(functions, 'localhost', 5001);

    const handleContactForm = httpsCallable(functions, 'handleContactForm');

    data.formType = formType;
    if (isValid) {
      isloading(true);

      handleContactForm(data)
        .then(() => {
          setError('submit', {
            type: 'server',
            message: 'Form submitted succesfully!',
          });
          isloading(false);
          setTimeout(() => {
            reset();
          }, 3000);
        })
        .catch(() => {
          setError('submit', {
            type: 'server',
            message: 'Something went wrong with submission! Try again',
          });
          isloading(false);
          setTimeout(() => {
            reset({ submit: '' });
          }, 5000);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="contact-us-form">
        <div className="product-form-section">
          <div className="contact-us-form-firstname contact-us-form-field">
            <input
              type="text"
              placeholder="First Name"
              className="contact-us-form-input"
              {...register('firstName', {
                required: {
                  value: true,
                  message: 'First Name is required',
                },
                pattern: {
                  value: /^[a-zA-Z ]+$/g,
                  message: 'Invalid Name. Avoid Special characters',
                },
              })}
            />
            {errors.firstName && (
              <div className="error-message">{errors.firstName.message}</div>
            )}
          </div>
          <div className="contact-us-form-lastname contact-us-form-field">
            <input
              type="text"
              placeholder="Last Name"
              className="contact-us-form-input"
              {...register('lastName', {
                required: {
                  value: true,
                  message: 'Last Name is required',
                },
                pattern: {
                  value: /^[a-zA-Z ]+$/g,
                  message: 'Invalid Name. Avoid Special characters',
                },
              })}
            />
            {errors.lastName && (
              <div className="error-message">{errors.lastName.message}</div>
            )}
          </div>
          <div className="contact-us-form-email contact-us-form-field">
            <input
              type="text"
              placeholder="Email"
              className="contact-us-form-input"
              {...register('email', {
                required: {
                  value: true,
                  message: 'Email is required',
                },
                pattern: {
                  value:
                    /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/g,
                  message: 'Invalid Email',
                },
              })}
            />
            {errors.email && (
              <div className="error-message">{errors.email.message}</div>
            )}
          </div>
          <div className="contact-us-form-phone contact-us-form-field">
            <input
              type="text"
              placeholder="Phone"
              className="contact-us-form-input"
              {...register('phoneNo', {
                required: {
                  value: true,
                  message: 'Number is required',
                },
                pattern: {
                  value: /^[0-9]{5,}$/g,
                  message: 'Please check the number',
                },
              })}
            />
            {errors.phoneNo && (
              <div className="error-message">{errors.phoneNo.message}</div>
            )}
          </div>
        </div>
        <div className="product-form-items">
          <div className="contact-us-form-company contact-us-form-field">
            <input
              type="text"
              placeholder="Company"
              className="contact-us-form-input"
              {...register('company', {
                required: {
                  value: true,
                  message: 'Company name is required',
                },
              })}
            />
            {errors.company && (
              <div className="error-message">{errors.company.message}</div>
            )}
          </div>
          <div className="contact-us-form-message contact-us-form-field">
            <textarea
              name=""
              placeholder="Message"
              className="contact-us-form-input"
              id=""
              cols={30}
              rows={6}
              {...register('message', {
                required: {
                  value: true,
                  message: ' This field is required',
                },
              })}
            />
            {errors.message && (
              <div className="error-message">{errors.message.message}</div>
            )}
          </div>
        </div>
        <div className="product-form-button">
          <button
            type="submit"
            className="contact-us-form-cta"
            {...register('submit')}
          >
            {!loading ? (
              buttonLabel ? (
                <span>{buttonLabel}</span>
              ) : (
                'Submit'
              )
            ) : (
              'Submitting...'
            )}
          </button>
        </div>
        {errors.submit && (
          <div className="form-error-message">{errors.submit.message}</div>
        )}
      </div>
    </form>
  );
};

export { ContactForm };

const contactFormQuery = () => {
  return useStaticQuery(graphql`
    query contactFormQuery {
      site {
        siteMetadata {
          recaptchaKey
        }
      }
    }
  `);
};
