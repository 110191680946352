import React, { useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PlayIcon from 'assets/svg/play-icon.svg';
import { CTALink, VideoModal } from 'components';

const FullWidthVideo = ({ data }: any) => {
  const [isModalOpen, setisModalOpen] = useState(false);
  const Image: any = getImage(data.image.localFile);
  const Logo: any =
    data.displayLogo && data?.displayLogo.localFile
      ? getImage(data?.displayLogo?.localFile)
      : null;
  const handleModalOpen = () => {
    setisModalOpen(true);
  };
  return (
    <div className="video-section">
      <div className="video-section-wrapper">
        <div className="video-section-img-wrapper">
          <GatsbyImage
            image={Image}
            alt={data?.image?.name || 'video poster'}
          />
          {Logo ? (
            <div className="video-section-logo-wrapper">
              <GatsbyImage image={Logo} alt="" />
            </div>
          ) : null}
          {data.showOverlay && (
            <div
              className="video-section-img-wrapper-overlay"
              style={{ opacity: `${data.overlayOpacity || 0.5}` }}
            />
          )}
        </div>
        <div className="video-section-content">
          <div className={`video-section-para text-${data?.textColor}`}>
            {data && data.title && (
              <h2 className="video-section-content-title">{data.title}</h2>
            )}
            {data && data.description && (
              <p className="video-section-content-tagline">
                {data.description?.data?.description}
              </p>
            )}
          </div>
          {data && data.ctaButton && !data.video && (
            <div className="video-section-button">
              <CTALink ctaLink={data.ctaLink} className="cta-btn">
                {data.ctaButton}
              </CTALink>
            </div>
          )}
        </div>
        {data.video && (
          <button
            type="button"
            className="videoplayer-action"
            onClick={() => {
              handleModalOpen();
            }}
          >
            <PlayIcon />
          </button>
        )}
      </div>
      <VideoModal
        isModalOpen={isModalOpen}
        setisModalOpen={setisModalOpen}
        ModalPosterImage={data.image}
        ModalVideo={data.video}
      />
    </div>
  );
};
export { FullWidthVideo };
