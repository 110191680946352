import React from 'react';
import { FamilyHistory, FamilyHistoryResponsive } from 'components';

const FamilyHistorySection = ({ data }: any) => {
  return (
    <div className="family-history-section-item">
      <FamilyHistory familyHistory={data} />
      <FamilyHistoryResponsive familyHistory={data} />
    </div>
  );
};

export { FamilyHistorySection };
