import React, { useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PlayIcon from 'assets/svg/play-icon.svg';
import { VideoModal } from 'components';

const VideoPlayer = ({ posterImage, SampleVideo }: any) => {
  const [isModalOpen, setisModalOpen] = useState(false);

  const Image: any = getImage(posterImage.localFile);
  const handleModalOpen = () => {
    setisModalOpen(true);
  };

  return (
    <div className="videoplayer">
      <GatsbyImage image={Image} alt={posterImage.name || ''} />
      {SampleVideo && (
        <button
          type="button"
          className="videoplayer-action"
          onClick={() => {
            handleModalOpen();
          }}
        >
          <PlayIcon />
        </button>
      )}
      <VideoModal
        isModalOpen={isModalOpen}
        setisModalOpen={setisModalOpen}
        ModalPosterImage={posterImage}
        ModalVideo={SampleVideo}
      />
    </div>
  );
};

export { VideoPlayer };
