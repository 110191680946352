import React from 'react';
import GoogleMapReact from 'google-map-react';
import MarkerIcon from 'assets/images/map-logo.png';

const mapStyle = () => {
  return {
    styles: [
      {
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      { elementType: 'geometry', stylers: [{ color: '#F8FAFA' }] },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      { elementType: 'labels.text.fill', stylers: [{ color: '#00443b' }] },
      { elementType: 'labels.text.stroke', stylers: [{ color: '#f5f1e6' }] },
      {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#c9b2a6' }],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#E0E8E7' }],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#00443b' }],
      },
      {
        featureType: 'landscape.natural',
        elementType: 'geometry',
        stylers: [
          { color: '#dfd2ae' },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: '#E0E8E7' }],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#00443b' }],
      },
      {
        featureType: 'poi.attraction',
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#00443b' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#E0E8E7' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#E0E8E7' }],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#00443b' }],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
          { color: '#00443b' },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'transit.line',
        elementType: 'labels.text.fill',
        stylers: [
          { color: '#00443b' },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'transit.line',
        elementType: 'labels.text.stroke',
        stylers: [
          { color: '#00443b' },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
          { color: '#dfd2ae' },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [{ color: '#E0E8E7' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#00443b' }],
      },
    ],
  };
};

const mapProp = {
  defaultCenter: { lat: -37.80984742712881, lng: 144.8605280977196 },
  defaultZoom: 10,
  styles: mapStyle,
  locations: [
    {
      locationName: 'TastyOne',
      address: 'TastyOne',
      position: {
        lat: -37.80984742712881,
        lng: 144.8605280977196,
      },
    },
  ],
};

const ContactMap = ({
  defaultCenter = mapProp.defaultCenter,
  locations = mapProp.locations,
  mapApiKey,
}: any) => {
  const renderMarkers = (map: any, maps: any) => {
    locations.map(
      (location: any) =>
        new maps.Marker({
          position: {
            lat: location.position.lat,
            lng: location.position.lng,
          },
          map,
          icon: MarkerIcon,
          title: location.address,
        })
    );
  };
  return (
    <div className="contact-us-map-section">
      <div className="contact-us-map-section-map">
        <div className="map" style={{ height: '450px', width: '100%' }}>
          <GoogleMapReact
            defaultZoom={17}
            defaultCenter={defaultCenter}
            options={mapProp.styles}
            yesIWantToUseGoogleMapApiInternals
            resetBoundsOnResize
            onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            bootstrapURLKeys={{
              key: mapApiKey,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export { ContactMap };
