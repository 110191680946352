import React from 'react';
import { BannerInfo, BannerSlider } from 'components';

const HeroBanner = ({ heroBannerContent, heroBanner, pageHeading }: any) => {
  const BannerContent = heroBannerContent;

  return (
    <div className="video-section-container right-radius">
      <BannerSlider heroBanner={heroBanner} />
      <BannerInfo
        bgColor="green"
        textColor="offwhite"
        captionColor="lightgreen"
        BannerContent={BannerContent}
        pageHeading={pageHeading}
      />
    </div>
  );
};

export { HeroBanner };
