/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { filterPosts, sortPostsDesc, sortPostsAsc } from 'Redux/actions';
import { FilterMobile } from 'components';
import Select from 'react-select';

export const SortAndFilter = ({ filters }: any) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const options: any = [
    { value: 'desc', label: 'Newest to oldest' },
    { value: 'asc', label: 'Oldest to newest' },
  ];
  const [sort, setSort] = useState('') as any;
  const sortStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: 'none',
      outline: 0,
      border: 0,
      boxShadow: 'none',
      color: '#fff',
      cursor: 'pointer',
      minHeight: '30px',
    }),
    option: (styles: any, { isDisabled }: any) => {
      return {
        ...styles,
        backgroundColor: 'none',
        color: '#00443b',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      };
    },
  };
  const handleClick = item => {
    if (isFilterOpen) {
      setFilter(item);
      setIsFilterOpen(false);
    } else {
      setIsFilterOpen(true);
    }
  };

  useEffect(() => {
    if (filter !== '') dispatch(filterPosts(filter) as any);
    if (sort === 'asc') {
      dispatch(sortPostsAsc() as any);
    } else if (sort === 'desc') {
      dispatch(sortPostsDesc() as any);
    }
  }, [filter, sort]);

  return (
    <div className="sort-filter-wrapper">
      <div className="sort">
        <p className="sort-label">Sort by</p>
        <Select
          className="sort-select"
          defaultValue={{ value: '', label: '' }}
          value={sort.value}
          onChange={e => setSort(e.value)}
          options={options}
          styles={sortStyles}
          classNamePrefix="select"
        />
      </div>
      <FilterMobile
        filters={filters}
        handleClick={handleClick}
        isFilterOpen={isFilterOpen}
      />
      <div className="filter">
        <p className="filter-label">Filter by Topic</p>
        <ul className={`${isFilterOpen ? 'active' : ''}`}>
          {filters &&
            filters.length > 0 &&
            filters.map((filterItem: any) => {
              return (
                <li
                  key={filterItem.title}
                  className={`${isFilterOpen ? 'active' : ''}`}
                >
                  {filterItem && filterItem.title && (
                    <button
                      type="button"
                      className={`${filterItem.sectionTheme || ''}`}
                      onClick={() => setFilter(filterItem.title)}
                    >
                      {filterItem.title}
                    </button>
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};
