/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import gsap from 'gsap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

const ProductSlider = ({
  data,
  parentSlug,
  setisModal,
  setSelectedProduct,
  productList,
}: any) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState();
  const swiperRef = useRef(null);
  const productRefs = useRef([]);
  const addProductRefs = el => {
    if (el && !productRefs.current.includes(el)) {
      productRefs.current.push(el);
    }
  };
  const updateIndex = () => {
    setCurrentSlide(swiperRef.current.swiper.realIndex);
  };
  useEffect(() => {
    setIsMobile(window.innerWidth < 768);
    const swiperInstance = swiperRef.current.swiper;

    if (swiperInstance) {
      swiperInstance.on('slideChange', updateIndex);
      gsap.to(
        productRefs.current[currentSlide + 1].querySelector(
          '.product-slider-items-image'
        ),
        { scale: 1.2 }
      );
      gsap.to(
        productRefs.current[currentSlide + 1].querySelector(
          '.product-slider-items-info'
        ),
        { opacity: 1 }
      );
      gsap.to(
        productRefs.current[currentSlide + 1].querySelector(
          '.product-slider-items-cta'
        ),
        { scale: 1.2 }
      );
    }
    return () => {
      if (swiperInstance) {
        swiperInstance.off('slideChange', updateIndex);
        gsap.to(
          productRefs.current[currentSlide + 1].querySelector(
            '.product-slider-items-image'
          ),
          { scale: 1 }
        );
        gsap.to(
          productRefs.current[currentSlide + 1].querySelector(
            '.product-slider-items-info'
          ),
          { opacity: 0 }
        );
        gsap.to(
          productRefs.current[currentSlide + 1].querySelector(
            '.product-slider-items-cta'
          ),
          { scale: 1 }
        );
      }
    };
  }, [updateIndex]);
  const handleProductSliderPopup = (item: any) => {
    const filteredProd = productList.filter(
      (prod: any) => prod.slug === item.slug
    );
    if (filteredProd.length) {
      setSelectedProduct(filteredProd[0]);
      setisModal(true);
      window.history.pushState('', '', `${parentSlug}${item.slug}`);
    }
  };
  const { title: sliderTitle, summary, products } = data;
  return (
    <div className="product-slider-wrapper">
      {data.subTitle && <p className="product-slider-title">{data.subTitle}</p>}
      {isMobile && (
        <div className="product-slider-details-container mobile">
          {sliderTitle && (
            <h2 className="product-slider-details-title">{sliderTitle}</h2>
          )}
          {summary && (
            <div className="product-slider-details-tagline">
              {summary?.data?.summary}
            </div>
          )}
        </div>
      )}

      <div className="product-slider">
        <div className="swiper-line" />

        <Swiper
          grabCursor
          speed={1200}
          navigation
          ref={swiperRef}
          slidesPerView={1.5}
          spaceBetween={20}
          modules={[Pagination, Navigation]}
          pagination={{
            el: '.swiper-pagination',
            clickable: true,
          }}
          breakpoints={{
            592: {
              slidesPerView: 1.5,
              spaceBetween: 15,
              centeredSlides: false,
            },
            768: {
              slidesPerView: 2.3,
              spaceBetween: 15,
              centeredSlides: false,
            },
            1024: {
              slidesPerView: 2.5,
              spaceBetween: 18,
              centeredSlides: false,
            },

            2000: {
              slidesPerView: 3.5,
              spaceBetween: 30,
              centeredSlides: false,
            },
          }}
        >
          {!isMobile && (
            <SwiperSlide>
              <div
                className="product-slider-details-container"
                ref={addProductRefs}
              >
                {sliderTitle && (
                  <h2 className="product-slider-details-title">
                    {sliderTitle}
                  </h2>
                )}

                {summary && (
                  <div className="product-slider-details-tagline">
                    {summary?.data?.summary}
                  </div>
                )}
              </div>
            </SwiperSlide>
          )}

          {products &&
            products.length > 0 &&
            products
              .sort((a: any, b: any) => a.title.localeCompare(b.title))
              .map((item: any) => {
                const { id, title, sliderImage, color } = item;
                const Image: any =
                  sliderImage && sliderImage.localFile
                    ? getImage(
                        sliderImage.localFile.childImageSharp?.gatsbyImageData
                      )
                    : null;

                return (
                  <SwiperSlide key={item.id}>
                    <div
                      className="product-slider-items-container"
                      key={id}
                      ref={addProductRefs}
                    >
                      <div className="product-slider-items-image">
                        <GatsbyImage image={Image} alt={title || ''} />
                      </div>
                      <div className="product-slider-items-info-wrapper">
                        {title && (
                          <h3 className="product-slider-items-title">
                            {title}
                            {color && <span>({color})</span>}
                          </h3>
                        )}
                        {/* {product_cuts && product_cuts.length > 0 && (
                          <div className="product-slider-items-info">
                            {product_cuts.map(
                              (tag: any, i: number) =>
                                `${tag.title} ${
                                  i !== product_cuts.length - 1 ? '(or)' : ''
                                } `
                            )}
                          </div>
                        )} */}
                        <button
                          type="button"
                          className="product-slider-items-link"
                          onClick={() => handleProductSliderPopup(item)}
                        >
                          <div className="product-slider-items-cta">
                            View product
                          </div>
                        </button>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          <div className="swiper-pagination" />
        </Swiper>
      </div>
    </div>
  );
};

export { ProductSlider };
