import React, { useEffect } from 'react';
import ReactModal from 'react-modal';

const ModalComponent = ({ closeModal, isModal, children, className }: any) => {
  useEffect(() => {
    if (isModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isModal]);

  return (
    <ReactModal
      isOpen={isModal}
      onRequestClose={closeModal}
      ariaHideApp={false}
      portalClassName={className}
      style={{
        content: {
          position: 'absolute',
          border: 'none',
          outline: 'none',
          background: 'none',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
      }}
    >
      {children}
    </ReactModal>
  );
};

export { ModalComponent };
