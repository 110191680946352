import { useStaticQuery, graphql } from 'gatsby';

const MainNav = () => {
  return useStaticQuery(graphql`
    query MainNav {
      strapiGlobal {
        header {
          phoneNumber
          phoneText
          mainNav {
            title
            slug
          }
          headerLinks {
            title
            slug
          }
        }
        footer {
          subscribeformTitle
          subscribeformtagline
          footerNav {
            title
            slug
            id
          }
          location {
            data {
              location
            }
          }
        }
        socialMediaLinks {
          facebookLink
          instagramLink
          linkedinLink
        }
      }
    }
  `);
};

export { MainNav };
