import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import Moment from 'react-moment';
import { SocialShare } from 'components';
import ReadArrow from 'assets/svg/readarrow.svg';

const ArticleComponent = ({ data, nextArticleSlug, parentSlug }: any) => {
  const Image: any = getImage(data.image.localFile);
  return (
    <div className="container article-page-wrapper">
      {data.title && <h1 className="article-page-title">{data.title}</h1>}
      <div className="article-page-details">
        <div className="article-page-details-main">
          {data.description && (
            <h3 className="article-page-details-tagline">
              {data.description?.data?.description}
            </h3>
          )}
          {data.content && (
            <div className="article-page-details-description">
              <ReactMarkdown className="htmlcontent">
                {data.content?.data?.content}
              </ReactMarkdown>
            </div>
          )}
        </div>
        <div className="article-page-details-sidebar">
          <div className="article-page-image">
            {Image && <GatsbyImage image={Image} alt={data.title} />}
          </div>
          <div className="article-page-share-wrapper">
            <div className="article-page-share-icons-wrapper">
              <SocialShare
                title={data.title}
                image={data.image.localFile.publicURL}
              />
            </div>
            {data.date && (
              <div className="article-page-Date">
                <Moment format="Do MMMM YYYY">{data.date}</Moment>
              </div>
            )}
          </div>
        </div>
      </div>
      {nextArticleSlug && (
        <div className="article-page-next-btn">
          <Link to={`/${parentSlug}/${nextArticleSlug}`}>
            Next Story <ReadArrow />
          </Link>
        </div>
      )}
    </div>
  );
};

export { ArticleComponent };
