import React, { useState, useEffect } from 'react';
import { CTALink, VideoPlayer } from 'components';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

export const WeeklyMarketUpdate = ({ data }: any) => {
  const [isMobile, setMobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setMobile(true);
    }
  }, []);

  return (
    <div className="weekly-market-update">
      <div className="container">
        <div className="weekly-market-update-wrapper">
          <div className="weekly-market-update-content">
            <div>
              {data && data.title && (
                <h2 className="weekly-market-update-content-title">
                  {data.title}
                </h2>
              )}
              {isMobile && (
                <div className="weekly-market-update-video">
                  <VideoPlayer
                    showModal
                    showPlayButton
                    posterImage={data.image}
                    SampleVideo={data.video}
                  />
                </div>
              )}
              <div className="weekly-market-update-content-desc">
                {data && data.summary.data.summary && (
                  <ReactMarkdown className="htmlcontent">
                    {data.summary.data.summary}
                  </ReactMarkdown>
                )}
              </div>
            </div>

            {data && !data.video && data.ctaLink && data.ctaButton && (
              <CTALink
                ctaLink={data.ctaLink}
                className="weekly-market-update-content-cta"
              >
                {data.ctaButton}
              </CTALink>
            )}
          </div>
          {!isMobile && (
            <div className="weekly-market-update-video">
              <VideoPlayer
                showModal
                showPlayButton
                posterImage={data.image}
                SampleVideo={data.video}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
