import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ParallaxEffect } from 'components';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

export const WhyTastyone = ({ data }: any) => {
  const { title, tagline, image, sectionSummary } = data;

  return (
    <div className="why-tastyone-section">
      <div className="why-tastyone-content text-green">
        <div className="why-tastyone-content-container">
          <div className="why-tastyone-content-details">
            <h2 className="why-tastyone-content-title">{title}</h2>
            {tagline && (
              <div className="why-tastyone-content-caption">
                <ReactMarkdown>{tagline}</ReactMarkdown>
              </div>
            )}
          </div>
          <div className="why-tastyone-image">
            <ParallaxEffect translateY={[-5, 10]}>
              {image?.localFile?.childImageSharp?.gatsbyImageData && (
                <GatsbyImage
                  image={image?.localFile?.childImageSharp?.gatsbyImageData}
                  alt={title}
                />
              )}
            </ParallaxEffect>
          </div>
        </div>
        {sectionSummary && sectionSummary.length > 0 && (
          <>
            <div className="why-tastyone-content-separator" />
            <div className="why-tastyone-content-para-wrapper">
              {sectionSummary.map((item: any) => {
                const { summary } = item;
                return (
                  <div
                    key={summary.data.summary}
                    className="why-tastyone-content-para-block"
                  >
                    <span />
                    <div className="why-tastyone-content-para font-body">
                      <ReactMarkdown>{summary.data.summary}</ReactMarkdown>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
