/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

export const BrandSlider = ({ data }: any) => {
  const breakpoints = {
    1024: {
      slidesPerView: 5,
    },
    1200: {
      slidesPerView: 6,
    },
  };
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    setDomLoaded(true);
  }, []);
  return (
    <div className="brand-section">
      <div className="brand-section-wrapper">
        {domLoaded && (
          <Swiper
            modules={[Autoplay]}
            slidesPerView={3}
            autoplay={{
              delay: 1000,
            }}
            loop
            spaceBetween={20}
            breakpoints={breakpoints}
          >
            {data &&
              data.length > 0 &&
              data.map((item: any) => {
                const Image: any = getImage(item?.image?.localFile);
                if (Image) {
                  return (
                    <SwiperSlide key={`brand-${item.id}`}>
                      <div className="brand-item">
                        {Image && (
                          <GatsbyImage image={Image} alt={item.image.name} />
                        )}
                      </div>
                    </SwiperSlide>
                  );
                }
                return false;
              })}
          </Swiper>
        )}
      </div>
    </div>
  );
};
