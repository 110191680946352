import React from 'react';
import { NumbersAnimation, ParallaxEffect } from 'components';
import { GatsbyImage } from 'gatsby-plugin-image';

export const ProcessNumbers = ({ dynamicSectionsBlock }: any) => {
  const {
    image,
    title,
    controlledFacilitiesValue,
    controlledFacilitiesTitle,
    australianGrownValue,
    australianGrownTitle,
    controlledTrucksValue,
    controlledTrucksTitle,
    employeesValue,
    employeesTitle,
    industryExperienceValue,
    industryExperienceTitle,
    veggiesTitle,
    veggiesValue,
  } = dynamicSectionsBlock;
  const controlValue = controlledFacilitiesValue.toLocaleString('en');
  const vegValue = veggiesValue.toLocaleString('en');
  return (
    <div className="process-numbers-section">
      <div className="process-numbers-section-wrapper">
        <div className="process-numbers-img">
          <ParallaxEffect
            translateY={[0, 10]}
            className="process-numbers-img-item"
          >
            {image?.localFile?.childImageSharp?.gatsbyImageData && (
              <GatsbyImage
                image={image?.localFile?.childImageSharp?.gatsbyImageData}
                alt={title}
                className="process-numbers-img-width"
              />
            )}
          </ParallaxEffect>
        </div>

        <div className="process-numbers-content container">
          <h2 className="font-xl text-white process-numbers-content-title">
            {title}
          </h2>
          <div className="process-numbers-content-figures ">
            <div className="process-numbers-content-figures-wrapper">
              <div className="process-numbers-content-figures-desc">
                <div className="process-numbers-content-figures-data">
                  <div className="process-numbers-content-figures-value">
                    <NumbersAnimation>{controlValue}</NumbersAnimation>
                  </div>
                  <div className="process-numbers-content-figures-heading">
                    <p>{controlledFacilitiesTitle}</p>
                  </div>
                </div>
                <div className="process-numbers-content-figures-data">
                  <div className="process-numbers-content-figures-value">
                    <NumbersAnimation>{controlledTrucksValue}</NumbersAnimation>
                  </div>
                  <div className="process-numbers-content-figures-heading">
                    <p>{controlledTrucksTitle}</p>
                  </div>
                </div>
                <div className="process-numbers-content-figures-data">
                  <div className="process-numbers-content-figures-value">
                    <NumbersAnimation>{australianGrownValue}</NumbersAnimation>
                    <span className="process-numbers-content-figures-symbol">
                      %
                    </span>
                  </div>
                  <div className="process-numbers-content-figures-heading">
                    <p>{australianGrownTitle}</p>
                  </div>
                </div>
              </div>
              <div className="process-numbers-content-figures-desc-two">
                <div className="process-numbers-content-figures-data">
                  <div className="process-numbers-content-figures-value">
                    <NumbersAnimation>{employeesValue}</NumbersAnimation>
                    <span className="process-numbers-content-figures-symbol">
                      +
                    </span>
                  </div>
                  <div className="process-numbers-content-figures-heading">
                    <p>{employeesTitle}</p>
                  </div>
                </div>
                <div className="process-numbers-content-figures-data">
                  <div className="process-numbers-content-figures-value">
                    <NumbersAnimation>{vegValue}</NumbersAnimation>
                  </div>
                  <div className="process-numbers-content-figures-heading">
                    <p>{veggiesTitle}</p>
                  </div>
                </div>
                <div className="process-numbers-content-figures-data">
                  <div className="process-numbers-content-figures-value">
                    <NumbersAnimation>
                      {industryExperienceValue}
                    </NumbersAnimation>
                    <span className="process-numbers-content-figures-symbol">
                      +
                    </span>
                  </div>
                  <div className="process-numbers-content-figures-heading">
                    <p>{industryExperienceTitle}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
