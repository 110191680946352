import React, { useEffect, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { TextAnimation } from 'components';

export const BannerVideoImage = ({ banner }: any) => {
  const BannerImageDesktop: any =
    banner && banner.imageDesktop && banner.imageDesktop.localFile
      ? getImage(banner.imageDesktop.localFile.childImageSharp?.gatsbyImageData)
      : null;
  const BannerImage: any =
    banner && banner.imageMobile && banner.imageMobile.localFile
      ? getImage(banner.imageMobile.localFile.childImageSharp?.gatsbyImageData)
      : null;
  return (
    <div className="banner-image">
      <div>
        {banner && banner.imageMobile && (
          <div className="banner-mobile-Image-wrapper">
            <GatsbyImage
              image={BannerImage}
              alt={
                (banner.imageMobile?.alternativeText &&
                  banner.title &&
                  banner.title) ||
                ''
              }
            />
            {banner.showOverlay && (
              <div
                className="banner-image-section showOverlay"
                style={{ opacity: `${banner.overlayOpacity || 0.5}` }}
              />
            )}
          </div>
        )}
        {banner && banner.imageDesktop && (
          <div className="banner-desktop-Image-wrapper">
            <GatsbyImage
              image={BannerImageDesktop}
              alt={
                (banner.imageDesktop?.alternativeText &&
                  banner.title &&
                  banner.title) ||
                ''
              }
            />
            {banner.showOverlay && (
              <div
                className="banner-image-section showOverlay"
                style={{ opacity: `${banner.overlayOpacity || 0.5}` }}
              />
            )}
          </div>
        )}
        {banner && banner.imageDesktop && banner.imageMobile && (
          <TextAnimation className="banner-image-content container">
            {banner.title && (
              <h2
                className={`banner-image-content-tagline font-xl ${
                  banner.bannerTitleColor
                    ? `text-${banner.bannerTitleColor}`
                    : 'text-ivory'
                }`}
              >
                {banner.title}
              </h2>
            )}
          </TextAnimation>
        )}
      </div>
    </div>
  );
};
