import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ButtonHoverAnimation, CTALink } from 'components';
import { Pagination } from 'swiper';

export const CardSlider = ({ data }: any) => {
  return (
    <div className="cardslider">
      <div className="container">
        <div className="cardslider-wrapper">
          <div className="cardslider-heading">
            {data.title && <h2 className=" card-title-md">{data.title}</h2>}
          </div>
          <Swiper
            className="card-swiper"
            slidesPerView={1.2}
            spaceBetween={12}
            modules={[Pagination]}
            pagination={{
              el: '.swiper-pagination',
              clickable: true,
            }}
            breakpoints={{
              592: {
                slidesPerView: 2.5,
                spaceBetween: 2,
              },
              992: {
                slidesPerView: 3.5,
                spaceBetween: 0,
              },
              1400: {
                slidesPerView: 4.5,
                spaceBetween: 2,
              },
            }}
          >
            <div className="cardslider-section">
              {data &&
                data.processList &&
                data.processList.length > 0 &&
                data.processList.map((item: any) => {
                  const { title, ctaLink, ctaButton, id } = item;

                  return (
                    <SwiperSlide key={id}>
                      <ButtonHoverAnimation scaleFrom={0.98} scaleTo={1}>
                        <div className="cardslider-section-carditem">
                          <div className="cardslider-section-carditem-wrapper">
                            <div>
                              {ctaLink && (
                                <CTALink
                                  ctaLink={ctaLink}
                                  className="cardslider-section-arrow"
                                >
                                  <span className="font-xs">{ctaButton}</span>
                                </CTALink>
                              )}
                            </div>

                            <div className="cardslider-description font-xs">
                              {title}
                            </div>
                          </div>
                        </div>
                      </ButtonHoverAnimation>
                    </SwiperSlide>
                  );
                })}
            </div>
            <div className="swiper-pagination" />
          </Swiper>
        </div>
      </div>
    </div>
  );
};
