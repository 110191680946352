/* eslint-disable no-shadow */
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { GatsbyImage } from 'gatsby-plugin-image';

const QualityBanner = ({ BannerContent, pageHeading }: any) => {
  const BannerSummary = BannerContent.bannerContent.bannerSummary;
  const { tagline } = BannerContent.bannerContent.tagline.data;
  const { description } = BannerContent.bannerContent.description.data;
  const { icons } = BannerContent.bannerContent;

  return (
    <div className="quality-standards-container banner-left-radius">
      <div className="container">
        <div className="quality-standards-wrapper">
          {pageHeading && <h1 className="page-heading">{pageHeading}</h1>}
          {tagline && <h3 className="quality-standards-heading">{tagline}</h3>}
          <div className="quality-standards-content">
            <div className="quality-standards-content-paragraph">
              {description && (
                <div className="quality-standards-para">
                  <ReactMarkdown className="htmlcontent">
                    {description}
                  </ReactMarkdown>
                </div>
              )}
              <div className="quality-standards-icon-desktop">
                <ul className="certified-icons">
                  {icons.map((item: any) => {
                    const { title, icon } = item;
                    return (
                      <li key={title}>
                        <GatsbyImage
                          image={
                            icon?.localFile?.childImageSharp?.gatsbyImageData
                          }
                          alt={title || ''}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="quality-standards-title-content">
              {BannerSummary.map((item: any) => {
                const { title, summary } = item;
                return (
                  <div className="quality-standards-title_content" key={title}>
                    {title && <h2>{title}</h2>}
                    {summary.data.summary && (
                      <p className="quality-standards-tagline">
                        {summary.data.summary}
                      </p>
                    )}
                  </div>
                );
              })}
              <div className="quality-standards-icon-mbl">
                <ul className="certified-icons">
                  {icons.map((item: any) => {
                    const { title, icon } = item;
                    return (
                      <li key={title}>
                        <GatsbyImage
                          image={
                            icon?.localFile?.childImageSharp?.gatsbyImageData
                          }
                          alt={title || ''}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { QualityBanner };
