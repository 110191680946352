import React, { useEffect, useRef } from 'react';
import VerticalScrollSlideArrow from 'assets/svg/VerticalScrollSlideArrow.svg';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';
import { useWindowSize } from 'hooks/useWindowSize';
import { SlideInAnimation } from 'components';

export const VerticalScroll = ({ data }: any) => {
  const { verticalScrollList, sectionTheme, headingColor } = data;
  const size = useWindowSize();
  const wrapperRef = useRef(null);
  const ImgRefs = useRef([]);
  const ContentRefs = useRef([]);
  gsap.registerPlugin(ScrollTrigger);

  if (verticalScrollList?.length === 0) {
    return null;
  }
  const addImgRefs = (el: any) => {
    if (el && !ImgRefs.current.includes(el)) {
      ImgRefs.current.push(el);
    }
  };
  const addContentRefs = (el: any) => {
    if (el && !ContentRefs.current.includes(el)) {
      ContentRefs.current.push(el);
    }
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    window.addEventListener('beforeunload', scrollToTop);

    gsap.set(ImgRefs.current, {
      zIndex: (i, target, targets) => {
        return targets.length - i;
      },
    });

    ImgRefs.current.forEach((panel, i) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: `.vertical-scroll-img-${data.id}`,
          start: () =>
            window.innerWidth < 768
              ? `${i + 1 === ImgRefs.current.length ? 200 : 50}% ${-1 * (Math.round(window.innerHeight / 2) * (i - 1))
              }`
              : `${i + 1 === ImgRefs.current.length ? 100 : 50}% -${Math.round(window.innerHeight) * i
              }`,
          end: () => `bottom bottom`,
          toggleActions: `play none ${i + 1 !== ImgRefs.current.length ? 'reverse' : 'reset'
            } none`,
          invalidateOnRefresh: true,
        },
      });
      tl.to(panel, {
        duration: window.innerWidth < 576 ? 1 : 0.4,
        ease: 'power1.out',
        height: '0',
      });
    });

    ScrollTrigger.create({
      trigger: `.vertical-scroll-img-${data.id}`,
      scrub: true,
      pin: true,
      start: () => `0 ${window.innerWidth < 768 ? '50%' : 0}`,
      end: () =>
        `+=${window.innerWidth < 768
          ? (verticalScrollList.length - 1) *
          Math.round(window.innerHeight / 2)
          : (verticalScrollList.length - 1) * window.innerHeight
        }`,
      invalidateOnRefresh: true,
    });

    ScrollTrigger.config({
      ignoreMobileResize: true,
    });
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 1800);
    return () => {
      window.removeEventListener('beforeunload', scrollToTop);
    };
  }, []);
  useEffect(() => {
    if (window.innerWidth > 768) {
      ScrollTrigger.refresh();
    }
  }, [size]);

  return (
    <>
      <div className={`vertical-scroll ${sectionTheme || ''}`}>
        <div className="vertical-scroll-wrapper" ref={wrapperRef}>
          <div className="vertical-scroll-content">
            {verticalScrollList.map((item: any, i: number) => {
              const { summary, id } = item;
              return (
                <div
                  className="vertical-scroll-content-slide"
                  ref={addContentRefs}
                  key={id}
                >
                  <div
                    className={`vertical-scroll-tagline ${headingColor || ''}`}
                  >
                    <ReactMarkdown className="htmlcontent">
                      {summary.data.summary}
                    </ReactMarkdown>
                  </div>
                  {verticalScrollList.length !== i + 1 && (
                    <VerticalScrollSlideArrow />
                  )}
                </div>
              );
            })}
          </div>
          <div className={`vertical-scroll-img vertical-scroll-img-${data.id}`}>
            {verticalScrollList.map((item: any) => {
              const { image, id } = item;
              const Image: any =
                image && image.localFile ? getImage(image.localFile) : null;
              return (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <div key={id}>
                  {image && (
                    <div className="vertical-scroll-img-slide" ref={addImgRefs}>
                      <GatsbyImage image={Image} alt="" />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className={`vertical-scroll vertical-scroll-mobile ${sectionTheme || ''
          }`}
      >
        {verticalScrollList.map((item: any, i: number) => {
          const { summary, id, image } = item;
          const Image: any =
            image && image.localFile ? getImage(image.localFile) : null;
          return (
            <div className="vertical-scroll-card" key={id}>
              <div className={`vertical-scroll-tagline ${headingColor || ''}`}>
                <ReactMarkdown className="htmlcontent">
                  {summary.data.summary}
                </ReactMarkdown>{' '}
              </div>
              {image && (
                <SlideInAnimation direction={i % 2 === 1 ? 'left' : 'right'}>
                  <div className="vertical-scroll-image">
                    <GatsbyImage image={Image} alt="" />
                  </div>
                </SlideInAnimation>
              )}
              {verticalScrollList.length !== i + 1 && (
                <div className="vertical-scroll-arrow">
                  <VerticalScrollSlideArrow />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};
