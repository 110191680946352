import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';

interface SeoProps {
  description?: string;
  lang?: string;
  title: string;
  author?: string;
  image?: any;
  className: string;
}

const Seo: FC<SeoProps> = ({
  description = '',
  lang = 'en',
  title,
  author = '',
  image = '',
  className,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const SeoData = SeoQuery();
  const { pathname } = useLocation();
  const {
    site: {
      siteMetadata: {
        defaultTitle,
        defaultDescription,
        defaultImage,
        siteUrl,
        isDevEnvironment,
        defaultAuthor,
      },
    },
    strapiGlobal: defaultSeo,
  } = SeoData;
  const seoTitle = title || defaultSeo.metaTitle || defaultTitle;
  const seoDescription =
    description || defaultSeo.metaDescription || defaultDescription;
  const seoImage = image
    ? `${siteUrl}${image}`
    : (defaultSeo?.shareImage?.localFile?.publicURL &&
        `${siteUrl}${defaultSeo?.shareImage?.localFile?.publicURL}`) ||
      (defaultImage && `${siteUrl}${defaultImage}`);
  const metaUrl = pathname && siteUrl ? `${siteUrl}${pathname}` : null;
  const canonical = pathname && siteUrl ? `${siteUrl}${pathname}` : null;
  const isDev = isDevEnvironment;
  const Author = author || defaultAuthor;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      bodyAttributes={{
        class: className,
      }}
      title={seoTitle}
      titleTemplate={`%s | ${defaultSeo?.metaTitle || defaultTitle}`}
      link={
        canonical
          ? [
              {
                rel: 'canonical',
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: 'description',
          content: seoDescription,
        },
        {
          name: 'image',
          content: seoImage,
        },
        {
          name: 'og:image:width',
          content: 200,
        },
        {
          name: 'og:image:height',
          content: 150,
        },
        {
          property: 'og:title',
          content: seoTitle,
        },
        {
          property: 'og:description',
          content: seoDescription,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: Author,
        },
        {
          name: 'twitter:title',
          content: `${seoTitle} - ${defaultSeo?.metaTitle || defaultTitle}`,
        },
        {
          name: 'twitter:description',
          content: seoDescription,
        },
        {
          name: 'twitter:image',
          content: seoImage,
        },
      ]
        .concat(
          seoImage
            ? [
                {
                  property: `og:image`,
                  content: seoImage,
                },
                {
                  property: `og:image:alt`,
                  content: seoTitle,
                },
              ]
            : []
        )
        .concat(
          isDev
            ? [
                {
                  property: `robots`,
                  content: `noindex`,
                },
              ]
            : []
        )}
    />
  );
};

export { Seo };

const SeoQuery = () => {
  return useStaticQuery(graphql`
    query seoQuery {
      site {
        siteMetadata {
          defaultTitle: title
          defaultDescription: description
          siteUrl
          defaultAuthor: author
          isDevEnvironment
          defaultImage: image
        }
      }
      strapiGlobal {
        defaultSeo {
          metaDescription
          metaTitle
          shareImage {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `);
};
