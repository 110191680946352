/* eslint-disable camelcase */
import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Moment from 'react-moment';
import Arrow from 'assets/svg/readarrow.svg';

const BlogCard = ({ item, parentUrl }: any) => {
  const { title, slug, article_tag, image, date, id } = item;
  const Image: any = getImage(image.localFile);
  return (
    <div className="card-container" key={id}>
      <div className="card-wrapper">
        <Link to={`/${parentUrl}/${slug}`}>
          <div className="card-wrapper-image">
            {image && <GatsbyImage image={Image} alt={title} />}

            <div className="card-wrapper-inner">
              {title && (
                <div className="card-wrapper-inner-title">
                  <p>{title}</p>
                </div>
              )}

              {article_tag && article_tag.title && (
                <div
                  className={`${
                    article_tag.sectionTheme ? article_tag.sectionTheme : ''
                  } card-wrapper-inner-category`}
                >
                  {article_tag.title}
                </div>
              )}
            </div>
          </div>
        </Link>
        <div className="card-wrapper-summary">
          {slug && (
            <div className="card-wrapper-summary-button">
              <Link to={`/${parentUrl}/${slug}`}>
                Read Story
                <Arrow />
              </Link>
            </div>
          )}

          {date && (
            <div className="card-wrapper-summary-date">
              <Moment format="Do MMMM YYYY">{date}</Moment>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { BlogCard };
