import React from 'react';
import { SlideInAnimation } from 'components';

const GreenCard = ({ Summary, pageTitle, className }: any) => {
  return (
    <div className={!!className && className}>
      <div className={`greencard-content-section-wrapper ${pageTitle}`}>
        {Summary &&
          Summary.length &&
          Summary.map((item: any) => {
            const { title, summary } = item;
            return (
              <SlideInAnimation
                className="greencard-content-wrapper"
                key={title}
                direction="right"
              >
                {title && <h3 className="greencard-content-title">{title}</h3>}
                {summary && summary.data && summary.data.summary && (
                  <div className="greencard-content-description">
                    {summary.data.summary}
                  </div>
                )}
              </SlideInAnimation>
            );
          })}
      </div>
    </div>
  );
};

export { GreenCard };
