import React, { FC, useEffect } from 'react';
import { Seo } from 'components';
import { ParallaxProvider } from 'react-scroll-parallax';
import { motion } from 'framer-motion';
import { useLocation } from '@reach/router';

export interface PageLayoutProps {
  children: JSX.IntrinsicElements['div'];
  title: string;
  className?: string;
  description: string;
  image: any;
}

export const PageLayout: FC<PageLayoutProps> = ({
  children,
  title,
  description,
  image,
  className = '',
}) => {
  const animationConfiguration = {
    initial: { clipPath: 'circle(97.7% at 51% 100%)' },
    animate: { clipPath: 'circle(78.9% at 51% 52%)' },
    exit: { clipPath: 'circle(97.7% at 51% 100%)' },
  };
  const { pathname } = useLocation();
  useEffect(() => {
    const scrollTimeOut = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);

    return () => clearTimeout(scrollTimeOut);
  }, [pathname]);
  return (
    <motion.div
      variants={animationConfiguration}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 2 }}
    >
      <Seo
        title={title}
        className={className}
        description={description}
        image={image}
      />
      <ParallaxProvider>{children}</ParallaxProvider>
    </motion.div>
  );
};
