import React from 'react';
import { BlogCard } from 'components';

export const BlogList = ({ list, parentUrl }: any) => {
  return (
    <div className="blog-list">
      {list.map((item: any) => {
        return <BlogCard item={item} key={item.id} parentUrl={parentUrl} />;
      })}
    </div>
  );
};
