import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import { Menu } from 'components';
import Logo from 'assets/svg/header-logo.svg';
import Facebook from 'assets/svg/facebook.svg';
import Instagram from 'assets/svg/instagram.svg';
import LinkedIn from 'assets/svg/linkedin.svg';
import { gsap } from 'gsap';
import { useScrollDirection } from 'hooks/useScrollDirection';

export interface HeaderProps {
  siteTitle: string;
}

const Header = ({ headerNav }: any) => {
  const scrollDirection = useScrollDirection();
  const { socialMediaLinks } = headerNav;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const HeaderRef = useRef(null);
  const MenuHandler = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setTimeout(() => {
      setIsMenuOpen(false);
    }, 10);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMenuOpen]);

  useEffect(() => {
    gsap.from(HeaderRef.current, {
      y: -100,
      duration: 1.5,
    });
    gsap.from('.logo-wrapper', {
      y: -100,
      duration: 1.5,
    });
    gsap.from('.menu-bar', {
      y: -100,
      duration: 1.5,
    });
  }, []);
  return (
    <header
      className={
        (isMenuOpen ? 'header active ' : 'header ') +
        (scrollDirection === 'down' ? 'fixed' : '')
      }
    >
      <Link to="/" className="logo-wrapper" onClick={closeMenu}>
        <Logo />
      </Link>
      <div className="header-container" ref={HeaderRef}>
        <div className="header-list">
          {headerNav.header.headerLinks.map((item: any) => {
            return (
              <div className="header-list-item header-link" key={item.slug}>
                <Link
                  to={
                    (item.slug && item.slug.startsWith('/')
                      ? `${item.slug}`
                      : `/${item.slug}`) || '/'
                  }
                  onClick={closeMenu}
                >
                  {item.title}
                </Link>
              </div>
            );
          })}
          <div className="header-list-item">
            <div className="header-list-social-media">
              <a
                href={socialMediaLinks?.facebookLink}
                className="header-media-link"
                target="_blank"
                onClick={closeMenu}
                rel="noreferrer"
              >
                <Facebook />
              </a>
              <a
                href={socialMediaLinks?.linkedinLink}
                className="header-media-link"
                target="_blank"
                onClick={closeMenu}
                rel="noreferrer"
              >
                <LinkedIn />
              </a>
              <a
                href={socialMediaLinks?.instagramLink}
                className="header-media-link"
                target="_blank"
                onClick={closeMenu}
                rel="noreferrer"
              >
                <Instagram />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={isMenuOpen ? 'menu-bar active' : 'menu-bar'}>
        <div className="menu-bar-text">{isMenuOpen ? '' : 'Menu'}</div>
        <button type="button" className="menu-bar-btn" onClick={MenuHandler}>
          <div className="menu-bar-btn-icon">
            <span />
            <span />
            <span />
          </div>
        </button>
      </div>
      <div
        className={isMenuOpen ? 'active menu-wrapper' : 'inactive menu-wrapper'}
      >
        <Menu
          header={headerNav.header}
          closeMenu={closeMenu}
          isMenuOpen={isMenuOpen}
          socialMediaLinks={socialMediaLinks}
        />
      </div>
    </header>
  );
};

export { Header };
