import React, { useEffect, useRef } from 'react';
import Sustainability from 'assets/svg/sustainability.svg';
import Ethics from 'assets/svg/ethics.svg';
import Network from 'assets/svg/network.svg';
import Knowledge from 'assets/svg/knowledge.svg';
import ReactMarkdown from 'react-markdown';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import ReadmoreArrow from 'assets/svg/readmore.svg';
import { Link } from 'gatsby';

export const CardSection = ({ cardData, aboutusIcon, equallyAligned }: any) => {
  gsap.registerPlugin(ScrollTrigger);
  const mobileStart = 'top bottom';
  const DesktopStart = 'top 70%';
  const trigger: any = useRef();
  useEffect(() => {
    gsap.from('.card-section-wrapper', {
      minHeight: 0,
      overflow: 'hidden',
      height: 0,
      padding: 0,
      stagger: 0.2,
      ease: 'Elastic.config(1,0.5)',
      scrollTrigger: {
        trigger: trigger.current,
        start: window.innerWidth < 768 ? mobileStart : DesktopStart,
      },
    });
    gsap.from('.card-section-wrapper > *', {
      opacity: 0,
      y: -40,
      stagger: 0.2,
      delay: 0.2,
      ease: 'easeOut',
      scrollTrigger: {
        trigger: trigger.current,
        start: 'top 70%',
      },
    });
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 1500);
  }, []);

  return (
    <div
      className={`card-section background-${cardData.backgroundColor}`}
      ref={trigger}
    >
      <div className="container">
        <div className="card-section-container">
          {cardData.twoCardSection.map((item: any, index: number) => {
            const { title, icon, description, sectionTheme } = item;
            let aboutusIcons;
            switch (icon) {
              case 'card1':
                aboutusIcons = <Sustainability />;
                break;
              case 'card2':
                aboutusIcons = <Ethics />;
                break;

              default:
                break;
            }
            let Icon;
            switch (icon) {
              case 'card1':
                Icon = <Network />;
                break;
              case 'card2':
                Icon = <Knowledge />;
                break;

              default:
                break;
            }

            return (
              <div
                className={`${
                  equallyAligned ? 'aligned-cards' : 'spaced-cards'
                } card-section-list`}
                key={title}
              >
                <div
                  className={`card-section-wrapper ${
                    item.sectionTheme ? item.sectionTheme : ''
                  } card-item${index}`}
                >
                  {aboutusIcon === true ? (
                    <div className="card-section-icon">{aboutusIcons}</div>
                  ) : (
                    <div className="card-section-icon">{Icon}</div>
                  )}
                  <div className="card-content">
                    {title && (
                      <h6 className={`card-title-md text-${sectionTheme}`}>
                        {title}
                      </h6>
                    )}
                    {description && (
                      <ReactMarkdown className={`text-${sectionTheme}`}>
                        {description.data.description}
                      </ReactMarkdown>
                    )}
                    {!equallyAligned && (
                      <div className={`card-cta text-${sectionTheme}`}>
                        <Link
                          to="/forward-thinking"
                          className={`text-${sectionTheme}`}
                        >
                          Read more
                          <ReadmoreArrow />
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
