import React, { useEffect, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { VideoPlayer } from 'components';

const ArticleMediaComponent = ({ data }: any) => {
  const [EventColumns, setsEventColumns]: any[] = useState([]);
  useEffect(() => {
    const eventColumnCount = window.innerWidth < 576 ? 1 : 2;
    const eventColumnArray: Array<any> =
      eventColumnCount === 2 ? [[], []] : [[]];

    data.events.forEach((element: any, i) => {
      switch (i % eventColumnCount) {
        case 0:
          eventColumnArray[0].push(element);
          break;
        case 1:
          eventColumnArray[1].push(element);
          break;
        default:
          eventColumnArray[0].push(element);
          break;
      }
    });
    setsEventColumns(eventColumnArray);
  }, [data.events]);
  return (
    <div className="article-page-media-content">
      <div className=" article-page-media-container article-page-media-desktop">
        {data.events.length === 0
          ? 'No Result Found'
          : EventColumns.map((eventitem: any, index: number) => {
              return (
                <div
                  className={`article-page-media-item 
              article-page-media-column${index + 1}`}
                >
                  {eventitem.map((item: any) => {
                    const { title, image, video, id } = item;
                    if (!image) {
                      return null;
                    }
                    const Image: any = getImage(image.localFile);
                    return (
                      <div className="article-page-media-section" key={id}>
                        {video && image ? (
                          <div
                            className={
                              video
                                ? 'article-page-media-wrapper video-width'
                                : 'article-page-media-wrapper'
                            }
                          >
                            {title && (
                              <div className="article-page-media-tagline">
                                {title}
                              </div>
                            )}
                            <VideoPlayer
                              showModal
                              showPlayButton
                              posterImage={image}
                              SampleVideo={video}
                            />
                          </div>
                        ) : (
                          <div className="article-page-media-wrapper">
                            {title && (
                              <div className="article-page-media-tagline">
                                {title}
                              </div>
                            )}
                            {Image && (
                              <div className="article-page-media-Image">
                                {Image && (
                                  <GatsbyImage image={Image} alt={title} />
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
      </div>
      <div className=" article-page-media-container article-page-media-mobile">
        {data &&
          data.events &&
          data.events.length > 0 &&
          data.events.map((eventItem: any) => {
            const { title, image, video, id } = eventItem;
            if (!image) {
              return null;
            }
            const Image: any = getImage(image.localFile);
            return (
              <div className="article-page-media-item">
                <div className="article-page-media-section" key={id}>
                  {video && image ? (
                    <div
                      className={
                        video
                          ? 'article-page-media-wrapper video-width'
                          : 'article-page-media-wrapper'
                      }
                    >
                      {title && (
                        <div className="article-page-media-tagline">
                          {title}
                        </div>
                      )}
                      <VideoPlayer
                        showModal
                        showPlayButton
                        posterImage={image}
                        SampleVideo={video}
                      />
                    </div>
                  ) : (
                    <div className="article-page-media-wrapper">
                      {title && (
                        <div className="article-page-media-tagline">
                          {title}
                        </div>
                      )}
                      {Image && (
                        <div className="article-page-media-Image">
                          {Image && <GatsbyImage image={Image} alt={title} />}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export { ArticleMediaComponent };
