import React, { FC, useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Facebook from 'assets/svg/Facebook-Share.svg';
import Pinterest from 'assets/svg/Pinterest-Share.svg';
import Instagram from 'assets/svg/Instagram-Share.svg';
import Twitter from 'assets/svg/Twitter-Share.svg';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  PinterestShareButton,
  TwitterShareButton,
  FacebookShareButton,
  // InstapaperShareButton,
} from 'react-share';

interface socialShareprops {
  title: string;
  image: any;
  showProductImage?: boolean;
}

// Need to use blog placeholder image instead of this
export const query = () => {
  return useStaticQuery(graphql`
    query backupQuery {
      site {
        siteMetadata {
          image
        }
      }
    }
  `);
};

export const SocialShare: FC<socialShareprops> = ({
  title,
  image,
  showProductImage = false,
}) => {
  const backup = query();
  const [url, setUrl] = useState('');
  const [location, setlocation] = useState('');
  useEffect(() => {
    setUrl(window.location.href);
    setlocation(window.location.origin);
  }, []);
  const imageUrl = `${location}${
    !image ? backup.site.siteMetadata.image : image
  }`;
  return (
    <div className="social_shares_wrapper">
      <span>Share:</span>
      <ul className="social_share">
        <li>
          <FacebookShareButton url={url} quote={title}>
            <Facebook />
          </FacebookShareButton>
        </li>
        {/* <li>
          <InstapaperShareButton url={url} quote={title}>
            <Instagram />
          </InstapaperShareButton>
        </li> */}
        <li>
          <TwitterShareButton url={url} title={title}>
            <Twitter />
          </TwitterShareButton>
        </li>
        <li>
          {showProductImage ? (
            <PinterestShareButton url={url} media={image} description={title}>
              <Pinterest />
            </PinterestShareButton>
          ) : (
            <PinterestShareButton
              url={url}
              media={imageUrl}
              description={title}
            >
              <Pinterest />
            </PinterestShareButton>
          )}
        </li>
      </ul>
    </div>
  );
};
